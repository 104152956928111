<template>
    <div class="page-container">
        <!-- 顶部切换组件 -->
        <personal-infro-top :iSelect="1" />
        <div class="page-content">
            <title-left :options="m_options" :is-select.sync='changeSelect' />

            <!-- 账号信息 -->
            <AcctounInfo v-show="changeSelect === 0" />
            <!-- 账号安全 -->
            <ChangePassword v-show="changeSelect === 1" />
            <!-- 实名认证 -->
<!--            <RealName :options="serStatus" v-show="changeSelect === 2" />-->
        </div>
    </div>
</template>
        
<script>
import { mapState } from 'vuex'
import PersonalInfroTop from '@/views/personalcenter/components/PersonalInfoTop.vue'
import TitleLeft from '@/views/personalcenter/components/TitleLeft.vue'
import AcctounInfo from '@/views/accountManage/changeInfo/index.vue'
import ChangePassword from '@/views/accountManage/changePassword/index.vue'
// import RealName from '@/views/accountManage/realName/index.vue'

export default {
    name: 'accountManage',
    components: { PersonalInfroTop, TitleLeft, AcctounInfo, ChangePassword },

    computed: {
        ...mapState({
            navActive: (state) => state.home.navActive,
            userInfo: (state) => state.user.userInfo,

        }),
    },
    watch: {
      $route: {
        immediate: true,
        deep: true,
        handler(nv){
          if(nv.params.changeSelect){
            this.changeSelect = nv.params.changeSelect
          }
        }
      }
    },
    data() {
        return {
            serStatus:'2',
            // this.userInfo.serStatus,
            changeSelect: 0,
            m_options: [
                {
                    title: '账号管理',
                    content: [
                        {
                            index: 0,
                            name: '账号信息',
                        },
                        {
                            index: 1,
                            name: '账号安全',
                        },
                        // {
                        //     index: 2,
                        //     name: '认证信息',
                        // },
                    ],
                }
            ],
            userName: '',
            userNum: ''

        }
    }
}
</script>
        
<style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
    background: #F4F4F4;

    .page-content {
        width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        margin-top: 10px;
        padding-bottom: 10px;
        // background-color: aqua;
    }
}
</style>
    