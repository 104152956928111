<template>
  <div>
    <div class="component-content">
      <div v-for="(itemp, indexp) in options" :key="indexp + 'w'">
        <p class="component-title">{{ itemp.title }}</p>
        <div class="component-option">
          <div class="item" v-for="(item, index) in itemp.content" :class="{ active: isSelect === item.index }" :key="index + 'h'" @click="handleNavClick(item.index)">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
  <script>
export default {
  name: 'TitleLeft',
  props: {
    isSelect: {
      default: 0
    },
    options: []
  },
  components: {},
  computed: {},
  methods: {
    handleClickHome() {
      this.$router.push('/')
    },
    handleNavClick(index) {
        this.$emit('update:isSelect', index)
        this.$emit('sendChangeSelect', index)
    },
  },
}
</script>
    
  <style lang="scss" scoped>
@import '@/styles/variables';

.component-content {
  width: 130px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: white;
  border-top-color: $mainColor;
  border-top-width: 5px;
  border-top-style: solid;
}
.component-title {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin: 30px 0 20px;
}
.img-logo {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}
.component-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #999999;
  .item {
    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
      color: $mainColor;
    }
    &.active {
      color: $mainColor;
    }
  }
}
</style>
    