<template>
  <div class="component-container1" :class="{ borderRadiusClass: borderRadiusClass }">
    <div class="component-content" >
      <div class="list">
        <div class="item" @mouseenter="handleCategoryMouseEnter(index1)" @mouseleave="handleCategoryMouseLeave(index1)" v-for="(item, index1) in allCategoryList" :key="item.key" v-show="item.value!='其他'">
          <div class="itemInfo">
            <div class="iconImg">
              <img src="@/assets/images/home/classify01.png" class="classifyIcon" v-if="index1 === 0"/>
              <img src="@/assets/images/home/classify02.png" class="classifyIcon" v-if="index1 === 1"/>
              <img src="@/assets/images/home/classify03.png" class="classifyIcon" v-if="index1 === 2"/>
              <img src="@/assets/images/home/classify04.png" class="classifyIcon" v-if="index1 === 3"/>
              <img src="@/assets/images/home/classify05.png" class="classifyIcon" v-if="index1 === 4"/>
              <img src="@/assets/images/home/classify06.png" class="classifyIcon" v-if="index1 === 5"/>
              <img src="@/assets/images/home/classify07.png" class="classifyIcon" v-if="index1 === 6"/>
            </div>
            <div class="clssifyCon">
              <div class="title-box">
                <div class="title">{{ item.value }}</div>
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="sub-list" v-if="item.children">
                <template
                  v-if="
                    item.children
                      .slice(0, 3)
                      .map((item) => {
                        return item.name
                      })
                      .join('/').length > 17
                  "
                >
                  <div @click="handleClick(index1, index2, -1)" class="secondary-text" v-for="(subitem, index2) in item.children.slice(0, 2)" :key="subitem.id">
                    <span v-if="index2 > 0">/</span>
                    <span class='sub-item'>{{ subitem.name }}</span>
                  </div>
                </template>
                <template v-else>
                  <div @click="handleClick(index1, index2, -1)" class="secondary-text" v-for="(subitem, index2) in item.children.slice(0, 3)" :key="subitem.id">
                    <span v-if="index2 > 0">/</span>
                    <span class='sub-item'>{{ subitem.name }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-if="popupList[index1].show && item.children.length > 0" class="popup-box">
            <div class="item" v-for="(more, index2) in item.children" :key="more.name + index2">
              <div class="title">{{ more.name }}</div>
              <div class="sub-list">
                <div @click="handleClick(index1, index2, index3)" class="secondary-text" v-for="(subitem, index3) in more.sonList" :key="subitem.name + index3">
                  <span class='sub-item'>{{ subitem.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'AllCategoryPop',
  components: {},
  computed: {
    ...mapState({
      navActive: (state) => state.home.navActive,
      allCategoryList: (state) => state.home.allCategoryList,
    }),
  },
  props: {
    isHome: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      popupList: [],
      borderRadiusClass:false,
    }
  },
  watch: {
    allCategoryList: {
      handler(newVal, oldVal) {
        if (!newVal || (newVal && newVal.length === 0)) {
          return
        }
        newVal.forEach(() => {
          this.popupList.push({ show: false })
        })
      },
      immediate: true,
    },
  },
  mounted() {
    // this.getAllCategoryList()
  },
  methods: {
    ...mapMutations('home', ['setNavActive', 'setAllCategoryListClickIndex']),
    ...mapActions('home', ['getAllCategoryList']),

    // 鼠标移入-商品分类
    handleCategoryMouseEnter(index) {
      this.borderRadiusClass = true;
      this.popupList[index].show = true
    },
    // 鼠标移出-商品分类
    handleCategoryMouseLeave(index) {
      this.borderRadiusClass = false;
      this.popupList[index].show = false
    },
    // 点击-分类
    handleClick(index1, index2, index3) {
      this.$router.push('/home/hire')
      this.setNavActive(1)
      this.setAllCategoryListClickIndex([index1, index2, index3])
      this.$bus.$emit('clickAllCategory', [index1, index2, index3])
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.component-container1 {
  width: 276px;
  position: relative;
  z-index: 300;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
}
.borderRadiusClass{
  border-radius: 16px 0 0 16px;
}
.component-content {
  height: 100%;
  > .title {
    width: 220px;
    height: 40px;
    background: #e1251b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $colorWhite;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  > .list {
    width: 100%;
    height: 496px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > .item {
      // position: relative;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        background: $mainColor;
        &:first-child{
          border-radius: 16px 0 0 0;
        }
        &:last-child{
          border-radius: 0 0 0 16px;
        }
        .title-box {
          color: $colorWhite;
          font-weight: bold;
        }
      }
      .itemInfo{
          display: flex;
          align-items: center;
          width: 100%;
        .classifyIcon{
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }
        .clssifyCon{
          width: 100%;
        }
      }
      .title-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        font-size: $primaryTextSize;
        color: $colorWhite;
        i {
          color: $colorWhite;
        }
      }
      .sub-list {
        width: 100%;
        padding-right: 10px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $colorWhite;
        .sub-item {
          flex-shrink: 0;
          color: $colorWhite;
          &:hover {
            color: $colorWhite;
            font-weight: bold;
          }
        }
      }
    }
  }
  .popup-box {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1000;
    background: #000000;
    opacity: 0.79;
    padding: 14px 0;
    padding-left: 20px;
    border-radius: 0px 16px 16px 0px;
    overflow: auto;
    height: 496px;
    .item {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 5px;

      .title {
        width: 100px;
        font-size: $secondaryTextSize;
        color: $colorWhite;
        text-align: right;
        margin-right: 19px;
        //text-wrap: nowrap;
      }
      .sub-list {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .secondary-text{
          margin-bottom: 13px;
        }
        .sub-item {
          margin-right: 20px;
          margin-bottom: 13px;
          cursor: pointer;
          color: $secondaryTextColor;
          &:hover {
            color: $colorWhite;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.icon-menu {
  width: 17px;
  margin-right: 5px;
  margin-top: 2px;
}
*::-webkit-scrollbar {
  border: none; /* 去掉边框 */
  width: 4px; /* 滚动条宽度 */
}
/* 仅在火狐浏览器中设置滚动条宽度为thin */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #BBBBBB transparent; /* 滑块颜色和透明背景 */
  }
  *::-moz-scrollbar-thumb {
    background-color: #BBBBBB; /* 火狐浏览器滑块颜色 */
  }
  *::-moz-scrollbar-track {
    background-color: transparent; /* 火狐浏览器轨道背景 */
  }
}
/* 滚动条滑块 */
*::-webkit-scrollbar-thumb {
  background-color: #BBBBBB; /* 谷歌浏览器滑块颜色 */
}
/* 滚动条轨道 */
*::-webkit-scrollbar-track {
  background-color: transparent; /* 谷歌浏览器轨道背景 */
}
</style>
