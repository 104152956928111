<template>
  <div id="app">
    <TopBar v-if="showTopBar" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { getToken, removeToken, setToken } from '@/utils/cookie'
import TopBar from '@/components/public/TopBar'

import Footer from '@/components/public/Footer'

export default {
  name: 'HomeView',
  components: {
    Footer,
    TopBar,
  },
  data() {
    return {
      showTopBar: false,
    }
  },
  mounted() {
    this.getUserInfo()
    this.showTopBar = false
  },
  beforeUpdate() {
    if (this.$route.path === '/login' || this.$route.path === '/register') {
      this.showTopBar = false
    } else {
      this.showTopBar = false
    }
  },
  methods: mapActions('user', ['getUserInfo']),
  created() {
    /**
     * 说明:添加此事件是因为vuex为短暂性缓存，刷新页面会丢失数据，所以刷新页面需要重新存储
     * 1. 关闭前hook存储session中，页面刷新执行顺序beforeunload->onunload->onload
     * 2. 数据加载状态created生命周期内，vuex的replaceState替换状态
     */
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
      setTimeout(() => {
        sessionStorage.removeItem('store')
      }, 1000)
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  min-height: 100vh;
  background: #f4f4f4;
}

</style>
