<template>
  <div class="component-container">
    <div class="component-content">
      <div class="box box-left">
        <div class="location" @mouseenter="handleLocationMouseEnter" @mouseleave="handleLocationMouseLeave">
          <i class="icon-location"></i>
          <p class="text-location">{{ userPosition && userPosition.name }}</p>
          <div v-if="showLocationPopup" class="popup-box location">
            <el-tabs v-model="active" @tab-click="handleClick">
              <el-tab-pane v-for="(item, index) in cityList" :key="item.title + index" :label="item.title" :name="item.title">
                <div class="list" v-if="item.children">
                  <div class="item" v-for="(subItem, index) in item.children" @click="handlePostionClick(subItem)" :key="subItem.id + index">{{ subItem.name }}</div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="text-hello">
          <p>
            您好
            <span class="important-text">
              {{ userInfo.userName || userInfo.phoneHide }}
            </span>
            欢迎来到每日循环
          </p>
        </div>
        <div class="login" v-if="userInfo.userUuid">
          <span class="line">|</span>
          <p class="button" @click="handleLogout">退出</p>
        </div>
        <div class="login" v-else>
          <p class="button button-login" @click="handleLogin">登录</p>
          <span class="line">|</span>
          <p class="button important-text" @click="handleRegister">免费注册</p>
        </div>
      </div>
      <div class="box box-right">
        <div class="user-center" v-if="userInfo.userUuid" @click="toUserCenter">个人中心</div>

        <div class="phone">
          <img src="@/assets/images/public/icon-phone.png" class="icon-phone" />
          <p>{{ phone }}</p>
        </div>
        <div class="mini" @mouseenter="handleMiniMouseEnter" @mouseleave="handleMiniMouseLeave">
          <p>小程序</p>
          <p class="icon-down"></p>
          <div v-if="showMiniPopup" class="popup-box mini">
            <img src="@/assets/images/public/xhmini.png" class="img-mini" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { all, hot } from '@/utils/city'
import { apiGetSysConfig } from '@/api/index'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  name: 'TopBar',

  components: {},
  data() {
    return {
      phone: '',
      showLocationPopup: false,
      showMiniPopup: false,
      active: '热门城市',
      cityList: [
        { title: '热门城市', children: [] },
        { title: 'ABCDE', children: [] },
        { title: 'FGHIJ', children: [] },
        { title: 'KLMNO', children: [] },
        { title: 'PQRST', children: [] },
        { title: 'UVWXY', children: [] },
        { title: 'Z', children: [] },
      ],
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      userPosition: (state) => state.user.userPosition,
    }),
  },
  created() {
    this.cityList.forEach((item) => {
      if (item.title === '热门城市') {
        item.children = hot
      } else {
        item.children = []
        item.title.split('').forEach((item2) => {
          if (all[item2]) {
            item.children = item.children.concat(all[item2])
          }
        })
      }
    })
    if(!this.userPosition.fullname){
      let _this=this;
      apiGetSysConfig({ code: 'jzl_map_config' }).then((res) => {
        const { key, secret } = res.data
        window._AMapSecurityConfig = {
          securityJsCode: secret,
        }
        AMapLoader.load({
          key: key, // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.CitySearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          let citySearch = new AMap.CitySearch()
          citySearch.getLocalCity(function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              // 查询成功，result即为当前所在城市信息
              console.log(result)
              let adcode=result.adcode
              console.log(adcode)
              let flag = false;
              for(let group in all){
                for(let i=0; i<all[group].length;i++){
                  if(adcode==all[group][i]['id']) {
                    _this.setUserPosition(all[group][i])
                    flag = true;
                    break;
                  }
                }
                if(flag){
                  break;
                }
              }
            }
          })
        });
      })
    }
  },
  mounted() {
    // 获取顶部电话
    apiGetSysConfig({ code: 'jzl_customer_service_phone' }).then((res) => {
      this.phone = res.data
    })
    this.updateUserInfo()
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapActions('user', ['updateUserInfo']),
    ...mapMutations('user', ['setUserPosition']),
    handleClick() {},
    // 鼠标移入-定位
    handleLocationMouseEnter() {
      this.showLocationPopup = true
    },
    // 鼠标移出-定位
    handleLocationMouseLeave() {
      this.showLocationPopup = false
    },
    // 鼠标移入-小程序
    handleMiniMouseEnter() {
      this.showMiniPopup = true
    },
    // 鼠标移出-小程序
    handleMiniMouseLeave() {
      this.showMiniPopup = false
    },
    // 登录
    handleLogin() {
      this.$router.push('/login')
    },
    // 退出
    handleLogout() {
      this.logout()
    },
    // 注册
    handleRegister() {
      this.$router.push('/register')
    },
    // 个人中心
    toUserCenter() {
      this.$router.push('/personal/home')
    },
    // 点击选择城市
    handlePostionClick(pos) {
      this.showLocationPopup = false
      this.setUserPosition(pos)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables';

.component-container {
  position: relative;
  z-index: 101;
  height: 30px;
  line-height: 30px;
  background: #f5f5f5;
  .component-content {
    width: $contentWidth;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    .box {
      display: flex;
    }
  }
}
.box-left {
  .location {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
    padding: 0 8px;
    margin-left: -8px;
    cursor: pointer;
    &:hover {
      color: $importantColor;
      background: #ffffff;
      .icon-location {
        background: url('@/assets/images/public/icon-position-red.png') no-repeat center / 100%;
      }
    }
    .icon-location {
      width: 11px;
      height: 14px;
      background: url('@/assets/images/public/icon-position-black.png') no-repeat center / 100%;
      margin-right: 6px;
    }
    position: relative;
  }

  .login {
    display: flex;
    align-items: center;
    .line {
      margin: 0 8px;
      color: #e0e0e0;
    }
    .button-login {
      margin-left: 19px;
    }
    .button {
      cursor: pointer;
      &:hover {
        color: $importantColor;
      }
    }
  }
}
.box-right {
  .user-center {
    margin-right: 30px;
    cursor: pointer;
    &:hover {
      color: $importantColor;
    }
  }
  .phone {
    display: flex;
    align-items: center;
    margin-right: 30px;
    .icon-phone {
      width: 16px;
      margin-right: 5px;
    }
  }
  .mini {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:hover {
      color: $importantColor;
      .icon-down {
        border-top-color: $importantColor;
        transform: rotate(180deg);
        margin-top: 0;
      }
    }
    .icon-down {
      width: 6px;
      margin-left: 7px;
      border: 3px solid transparent;
      border-top-color: $primaryTextColor;
      margin-top: 5px;
    }
  }
}

.popup-box.location {
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 10;
  width: 354px;
  min-height: 160px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
    .item {
      margin: 0 12px 10px;
      color: $primaryTextColor;
      cursor: pointer;
      &:hover {
        color: $importantColor;
      }
    }
  }
}
.popup-box.mini {
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 10;
  width: 114px;
  height: 114px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  .img-mini {
    width: 103px;
  }
}

::v-deep .el-tabs__header {
  margin: 0;
  padding-left: 8px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background: #f3f3f3;
}
::v-deep .el-tabs__item {
  height: 36px;
  padding: 0 5px;
  font-size: $secondaryTextSize;
  color: $secondaryTextColor;
  line-height: 36px;
}

::v-deep .el-tabs__item:hover {
  color: $primaryTextColor;
}
::v-deep .el-tabs__item.is-active {
  color: $primaryTextColor;
  font-weight: bold;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
</style>
