<template>
    <div class="box-banner" v-show="showBanner">
      <el-carousel height="700px" :interval="5000" arrow="never" @change='bannerChange'>
        <el-carousel-item v-for="(item, index) in bannerList" :key="item + index" style='display: flex;justify-content: center' >
          <img :src="item.imgSrc" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" class="img" @click="handleBannerClick(item)"/>
        </el-carousel-item>
      </el-carousel>
      <div class="tabMenu"><TabMenu/></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { apiGetBannerList } from '@/api/index.js'
import { mapActions, mapMutations } from 'vuex'
import TabMenu from './TabMenu.vue';
export default {
  name: 'HomeBanner',
  components: {TabMenu},
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      navActive: (state) => state.home.navActive,
    }),
  },
  data() {
    return {
      activeIndex: 0,
      bannerList: [],
      showBanner:false,

    }
  },
  watch: {
    navActive: {
      handler(newVal, oldVal) {
        if ((this.$route.path === '/' || this.$route.path === '/home/home') && newVal === 0) {
          this.showBanner = true
        } else {
          this.showBanner = false
        }
      },
      immediate: true,
    },
  },

  mounted() {
    apiGetBannerList({ spaceId: '13' }).then((res) => {
      this.bannerList = res.data.data
    })
  },
  methods: {
    ...mapActions('user', ['updateUserInfo']),
    // 登录
    toLogin() {
      this.$router.push('/login')
    },
    // 个人中心
    toUserCenter() {
      this.$router.push('/personal/home')
    },
    // banner 点击
    handleBannerClick(item) {
      window.location.href = item.hrefUrl
    },
    bannerChange(idex){

    },

  },
}
</script>
<style lang="scss">
.el-message-box_my {
  background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables';
  .box-banner{
    position: relative;
    margin: 0 auto;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* 图片等比缩放填充容器 */
      cursor: pointer;
    }
    .tabMenu{
      position: absolute;
      bottom: -64px;
      z-index: 99;
      width: 1300px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 80px;
  }
</style>
