import request from '@/utils/request'

// 用户咨询留言
export function consultLeaveMsg(data) {
  return request({
    url: '/appApi/jzl/intentOrder/consultLeaveMsg',
    method: 'post',
    data,
  })
}

// 咨询留言列表
export function getConsultLeaveMsgList(data) {
  return request({
    url: '/appApi/jzl/intentOrder/getConsultLeaveMsgList',
    method: 'post',
    data,
  })
}
