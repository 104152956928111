<template>
  <div class="page-container">
    <div class="page-content">
      <UserInfoTop />
      <div class="ui-page-content">
        <div class="page-content-left">
          <img class="page-content-left-box" src="@/assets/images/public/icon-login-left-image.png" />
        </div>
        <div class="page-content-right">
          <div class="page-content-right-box">
            <div class="page-content-right-child-box">
              <p class="p-welcome">欢迎登录</p>
              <div class="div-login-method">
                <p :class="[!isSelPassword ? 'p-tip3' : 'p-tip4']" v-on:click="handleSmsLogin">短信登录</p>
                <p :class="[isSelPassword ? 'p-tip3' : 'p-tip4']" style="margin-left: 30px" v-on:click="handlePasswordLogin">密码登录</p>
              </div>
              <el-form class="el-form-content submit_form" :rules="dataRules" :model="loginData">
                <el-form-item prop="telephone" v-show="!isSelPassword">
                  <el-input v-model="loginData.telephone" type="number" placeholder="请输入您的手机号" class="el-item-text" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)" onkeyup="this.value = this.value.replace(/[^\d]/g,'');">
                    <el-button slot="suffix" type="text" class="el-item-e1251b el-item-button" :disabled="countdown > 0" @click="startTimer">{{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}</el-button>
                  </el-input>
                </el-form-item>
                <el-form-item prop="code" v-show="!isSelPassword">
                  <el-input v-model="loginData.code" type="number" placeholder="请输入验证码" class="el-item-text" maxlength="6" oninput="if(value.length>6)value=value.slice(0,6)"></el-input>
                </el-form-item>

                <el-form-item prop="telephone" v-show="isSelPassword">
                  <el-input v-model="loginData.telephone" placeholder="请输入您的手机号" class="el-item-text"></el-input>
                </el-form-item>
                <el-form-item v-show="isSelPassword">
                  <el-input v-model="loginData.password" placeholder="请输入密码" class="el-item-text" show-password minlength="4" maxlength="20"></el-input>
                </el-form-item>

                <!-- <el-button slot="suffix" type="text" icon="el-icon-view"></el-button> -->
                <el-button class="el-item-button2" @click="handleUserLogin">登录</el-button>
                <div class="el-item-check-agreement">
                  <el-checkbox v-model="isAgreement">登录即视为同意</el-checkbox>
                  <a href="https://file.jupai.net/jupai/jlease/pcyxAgreement.html" target="agreement">《每日循环用户注册协议》</a>
                  及
                  <a href="https://file.jupai.net/jupai/jlease/pcPrivacypolicy.html" target="private">《隐私政策》</a>
                </div>
              </el-form>
              <div class="right-skip-register">
                <p class="p-skip-register" v-on:click="handleSkipRegister">免费注册</p>
                <p class="p-skip-register" v-on:click="handleForgetPassword" style="color: #bbbbbb" v-show="isSelPassword">忘记密码</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="rDialogVisible1" center :show-close="false" :close-on-click-modal="false" class="whz-el-dialog1">
      <div class="dialog-content">
        <p class="dialog-content-title">选择身份</p>
        <p class="dialog-content-info">请选择您的身份，以便为您提供更好的租赁信息服务</p>
        <div class="dialog-service-merchant">
          <div class="dialog-content-select">
            <p class="dialog-content-select-p1">服务商家</p>
            <p class="dialog-content-select-p2">生产加工企业、租赁公司（民营）二手物资供应商、物流公司等。</p>
          </div>
          <p class="dialog-content-checkbox" :class="{ active: isSelectService }" v-on:click="handleSelectService"></p>
        </div>
        <div class="dialog-construction-enterprise">
          <div class="dialog-content-select">
            <p class="dialog-content-select-p1">施工企业</p>
            <p class="dialog-content-select-p2">国企、央企、承建单位。</p>
          </div>
          <p class="dialog-content-checkbox" :class="{ active: isSelectConstruction }" v-on:click="handleSelectConstruction"></p>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <!-- <el-button type="info" @click="rDialogVisible1 = false">取消</el-button> -->
        <el-button type="primary" @click="handleSelectServiceProvider">确定并登录</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UserInfoTop from './components/UserInfoTop.vue'
import { loginByCode, bindingAccountIdentity } from '@/api/user'
import { sendSMS } from '@/api/common'
import { setToken } from '@/utils/cookie.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import { checkVipLimit } from '@/api/detail'
export default {
  name: 'LoginIndex',
  components: { UserInfoTop },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      detailInfo: (state) => state.detail.detailInfo,
    }),
  },
  watch: {
    '$store.state.user.userInfo': {
      handler(newValue, oldValue) {
        this.userInfoNew = newValue;
        // 在这里处理状态值变化的逻辑
      },
      deep: true // 如果需要深度监听对象内部值的变化，可以加上 deep: true
    }
  },
  data() {
    // 校验手机号
    var checkPhone = (rule, value, callback) => {
      console.log(value)
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3456789]\d{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确手机号'))
        }
      }
    }
    var checkCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('验证码不能为空'))
      }
    }
    return {
      loginData: {
        telephone: '',
        code: '',
        password: '',
      },
      isSelPassword: false,
      isAgreement: false,
      countdown: 0,
      dataRules: {
        telephone: [
          // { required: true, message: '请输入正确手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' },
        ],
        code: [
          // { required: true, message: '', trigger: 'blur' },
          { validator: checkCode, trigger: 'blur' },
        ],
      },
      rDialogVisible1: false,
      isSelectService: false,
      isSelectConstruction: false,
      serStatus: 0, // 服务商标识 0否，1是
      entStatus: 0, // 施工企业标识
      uuid: '',
      userInfoNew: {}
    }
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    ...mapMutations('detail',['setGoodsInfoReset','setVipShowFlag','setErrorInfo']),
    // handleBackButton() {
    //   // 在这里处理回退逻辑
    //   console.log('用户点击了浏览器的回退按钮');
    //   // 如果需要阻止回退可以使用history.pushState实现
    //   console.log(this.$store.state.detail.detailInfo)
    //   this.setGoodsInfoReset();
    // },
    handleSmsLogin() {
      this.isSelPassword = false
    },
    handlePasswordLogin() {
      this.isSelPassword = true
    },
    // 免费注册
    handleSkipRegister() {
      this.$router.push('/register')
    },
    // 忘记密码
    handleForgetPassword() {
      this.$router.push('/accountManage/changePassword')
      // this.$router.push({name:'accountManage', params: { changeSelect: 1}})
    },
    handleUserLogin() {
      if (!this.loginData.telephone || this.loginData.telephone == '') {
        this.$message('请输入手机号！')
        return
      }
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.loginData.telephone)) {
        return
      }
      if (this.isSelPassword) {
        // 密码登录
        if (!this.loginData.telephone) {
          this.$message('手机号不能为空')
        } else if (!this.loginData.password) {
          this.$message('密码不能为空')
        } else if (!this.isAgreement) {
          this.$message('请仔细阅读并勾选协议')
        } else {
          loginByCode({
            telephone: this.loginData.telephone,
            password: this.loginData.password,
          }).then((res) => {
            var data = res
            if (data.success) {
              let { userUuid, authenticationStatus } = data.data
              setToken(userUuid)
              this.getUserInfo().then((res) => {
                let userInfoObj = this.$store.state.user.userInfo;
                this.goodsDetailGo(userInfoObj);
                // this.$router.push('/')
              })
            } else {
              this.$message(data.msg)
            }
          })
        }
      } else {
        // 验证码
        if (!this.loginData.telephone) {
          this.$message('手机号不能为空')
        } else if (!this.loginData.code) {
          this.$message('验证码不能为空')
        } else if (!this.isAgreement) {
          this.$message('请仔细阅读并勾选协议')
        } else {
          loginByCode({
            telephone: this.loginData.telephone,
            code: this.loginData.code,
          }).then((res) => {
            var data = res
            if (data.success) {
              const { userUuid, serStatus, entStatus } = data.data
              this.uuid = userUuid + '@uuid'
              if ((!serStatus || serStatus === 0) && (!entStatus || entStatus === 0)) {
                this.rDialogVisible1 = true
              } else {
                setToken(userUuid)
                this.getUserInfo().then((res) => {
                  let userInfoObj = this.$store.state.user.userInfo;
                  this.goodsDetailGo(userInfoObj);
                  // this.$router.push('/')
                })
              }
            } else {
              this.$message(data.msg)
            }
          })
        }
      }
    },
    startTimer() {
      if (!this.loginData.telephone || this.loginData.telephone == '') {
        this.$message('请输入手机号！')
        return
      }
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.loginData.telephone)) {
        return
      }

      var data = {
        telephone: this.loginData.telephone,
      }
      sendSMS(data).then((res) => {
        console.log(res)
        if (res.code == '000000') {
          //获取验证码成功
          this.countdown = 59
          const interval = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--
            } else {
              clearInterval(interval)
            }
          }, 1000)
        } else {
          this.$message(res.msg)
        }
      })
    },
    handleSelectService() {
      // 服务商家
      this.isSelectService = !this.isSelectService
      if (this.isSelectService) {
        this.serStatus = 1
      } else {
        this.serStatus = 0
      }
    },
    handleSelectConstruction() {
      // 施工单位
      this.isSelectConstruction = !this.isSelectConstruction
      if (this.isSelectConstruction) {
        this.entStatus = 1
      } else {
        this.entStatus = 0
      }
    },
    handleSelectServiceProvider() {
      if (this.serStatus === 0 && this.entStatus === 0) {
        return this.$message("请选择身份")
      }
      this.rDialogVisible1 = false
      let userUuid = this.uuid.substring(0, this.uuid.lastIndexOf('@uuid'))
      bindingAccountIdentity({
        userUuid: userUuid,
        serStatus: this.serStatus,
        entStatus: this.entStatus,
      }).then((res) => {
        var data = res
        if (data.success) {
          setToken(userUuid)
          this.getUserInfo().then((res) => {
            this.$router.push('/')
          })
        } else {
          this.$message(data.msg)
        }
      })
    },
    goodsDetailGo(info) {
      let detailInfo = this.$store.state.detail.detailInfo;
      if(detailInfo && detailInfo.infoNo){
        if (info.vipFlag == 1) {
          //已经是会员
          this.$router.push({
            path: '/product/detail',
            query: {
              infoNo: detailInfo.infoNo,
              subheads: detailInfo.subheads || '',
              minheads: 'home',
              specId: detailInfo.specId,
            },
          })
          this.$emit('refresh') // 触发当前组件的 refresh 事件
        } else {
          //不是会员，判断
          var params = {
            // infoNo: detailInfo.infoNo
            infoNo:'CZ2024022978621'
          }
          checkVipLimit(params).then((res) => {
            if (res.success) {
              // 登录成功后未开通会员，弹窗领取会员后可查看详情
              this.$router.push({
                path: '/product/detail',
                query: {
                  infoNo: detailInfo.infoNo,
                  subheads: detailInfo.subheads || '',
                  minheads: 'home'
                },
              })
              this.$emit('refresh') // 触发当前组件的 refresh 事件
            } else if (res.code == 'A1006') {
              this.$router.push({
                path: detailInfo.currentRouter,
              })
              let detailinfo = { vipShowFlag: true }
              this.setVipShowFlag(detailinfo)
            } else {
              this.$router.push({
                path: detailInfo.currentRouter,
              })
              let detailinfo = { errorInfo: true, message: res.msg}
              this.setErrorInfo(detailinfo)
              // this.$message({ type: 'info', message: res.msg });
            }
          })
        }
      }else{
        this.$router.push('/')
      }

    },
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  .page-content {
    width: 100%;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    // align-items: center;
    background-image: url('@/assets/images/public/icon-login-background.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 100% 100%;

    // background: url('@/assets/images/public/icon-login-background.png') no-repeat #f05a19;
    .ui-page-content {
      width: $contentWidth;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }
  }
}
.page-content-left {
  flex: 1;
  display: flex;
  margin-left: -97px;
  // justify-content: end;
  .page-content-left-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 748px;
    height: 487px;
    .p-tip1 {
      font-size: 56px;
      font-weight: bold;
      color: white;
    }
    .p-tip2 {
      font-size: 22px;
      color: white;
      margin-top: 13px;
    }
  }
}
.page-content-right {
  flex: 1;
  display: flex;
  justify-content: right;
  margin-top: 21px;
  .page-content-right-box {
    background: white;
    width: 429px;
    height: 492px;
    border-radius: 16px;
    margin-bottom: 142px;
    padding: 56px 56px 0;
    .page-content-right-child-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .p-welcome {
        font-size: 30px;
        color: #333333;
      }
    }
  }
}
.div-login-method {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  margin-top: 37px;
  cursor: pointer;
  .p-tip3 {
    font-size: 16px;
    color: $color1f;
  }
  .p-tip4 {
    font-size: 16px;
    color: $colorA6;
  }
}
.el-form-content {
  margin-top: 20px;
  .el-item-text {
    // margin-top: 2px;
    color: #bbbbbb;
  }
  .el-item-button {
    color: $mainColor;
    padding: 12px 10px;
    &:hover {
      color: $mainColor;
    }
    &:focus {
      color: $mainColor;
    }
  }
  .el-item-button2 {
    width: 100%;
    height: 40px;
    margin-top: 16px;
    background: $mainColor;
    color: white;
    &:hover {
      color: white;
      border-color: transparent;
    }
    &:focus {
      color: white;
      border-color: transparent;
    }
  }
}
.right-skip-register {
  margin-top: 39px;
  display: flex;
  justify-content: center;
  .p-skip-register {
    cursor: pointer;
    width: 80px;
    font-size: 14px;
    color: $mainColor;
    text-align: center;
  }
}

::v-deep .el-checkbox .el-checkbox__inner {
  border-radius: 50% !important;
  overflow: hidden;
  border-color: #ececec;
  &:hover {
    border-color: $mainColor;
  }
}

::v-deep .el-checkbox .is-checked .is-focus .el-checkbox__inner {
  background-color: $mainColor;
  border-color: $mainColor;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: $mainColor;
  border-color: $mainColor;
}

::v-deep .el-checkbox .el-checkbox__label {
  font-size: 12px;
  color: $colorA6;
}
::v-deep .el-checkbox .is-checked + .el-checkbox__label {
  color: $colorA6;
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

.el-item-check-agreement {
  display: flex;
  color: $colorA6;
  font-size: 12px;
  text-wrap: nowrap;
  margin-top: 16px;
  align-items: center;
}

::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}

// 对话框
.whz-el-dialog1 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #bbbbbb;
      margin-top: 12px;
    }
    .dialog-service-merchant {
      width: 380px;
      height: 101px;
      background-image: url('@/assets/images/public/icon_service_merchant.png');
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 29px;
      justify-content: space-between;
    }

    .dialog-construction-enterprise {
      width: 380px;
      height: 101px;
      background-image: url('@/assets/images/public/icon_construction_enterprise.png');
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 17px;
      justify-content: space-between;
    }

    .dialog-content-select {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-left: 96px;
    }
    .dialog-content-select-p1 {
      font-size: 20px;
      color: #ffffff;
    }
    .dialog-content-select-p2 {
      margin-top: 5px;
      width: 183px;
      font-size: 12px;
      color: #ffffff;
    }
    .dialog-content-checkbox {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      background-image: url('@/assets/images/public/icon_select_normal_status.png');
      &.active {
        background-image: url('@/assets/images/public/icon_select_status.png');
      }
    }
    .dialog-content-checkbox ::v-deep .el-checkbox__inner {
      width: 24px;
      height: 24px;
    }
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 460px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 122px;
    height: 38px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 25px 35px;
  }
  ::v-deep .el-button + .el-button {
    margin-left: 22px;
  }
}
.submit_form {
  width: 100%;
}
.submit_form .el-form-item {
  margin-bottom: 16px;
}
</style>
