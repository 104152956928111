<template>
  <div class="page-container">
<!--    <img v-if='navActive === 0' src='@/assets/images/home/springFestivalTop.jpg' style='width: 100%;min-width: 1200px;' />-->
    <Header @home="toHome" />
    <div class="page-content">
      <div v-if="navActive === 0" class="index-content homeIndex">
        <!-- <Banner /> -->
        <!-- <VIP v-if="userInfo.vipFlag != 1" :isShowDialog="showDialog" :showImg="1" @set-show-dialog="setShowDialog" :isShowFlipDialog="showDialog"/> -->
        <!-- <VIP :isShowDialog="showDialog" :showImg="1" @set-show-dialog="setShowDialog" :isShowFlipDialog="showDialog"/> -->
        <!-- <Adv/> -->
        <Recommend @set-vip-show-top="setVipShow" :list="recommendList" />
        <category @set-vip-show-top="setVipShow" :list="visitTimesList" />
      </div>
      <!-- <div v-else>
        <VIP :isShowDialog="showDialog" :showImg="0" @set-show-dialog="setShowDialog" :isShowFlipDialog="showDialog" />
      </div> -->
      <!-- 切换导航保留筛选条件 -->
      <div v-if="navActive > 0" class="index-content">
        <GoodsList @set-vip-show-top="setVipShow" title="出租" key="出租" />
      </div>
    </div>
    <!--首页客户电话banner-->
    <!-- <div class='customerPhone' v-if='navActive === 0'>
      <img src='@/assets/images/home/customerPhoneBanner.jpg' style='width:100%;'>
    </div> -->
    <!--新年氛围-->
<!--    <transition name='fade' v-if='navActive === 0'>-->
<!--      <div v-if='springFestivalVisible' id='newyear'>-->
<!--        <img src='@/assets/images/home/springFestivalBottom.png' style='width:100%;'>-->
<!--        <img src='@/assets/images/home/springFestivalClose.png' class='close' @click='closeNewyear'>-->
<!--      </div>-->
<!--    </transition>-->
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Header from '@/components/public/Header.vue'
// import Banner from './components/Banner.vue'
import Recommend from './components/Recommend.vue'
import Category from './components/Category.vue'
// import VIP from './components/VIP.vue'
import GoodsList from './components/GoodsList.vue'
// import Adv from '@/views/home/components/Adv.vue'
import { apiRecommendList } from '@/api/index'

export default {
  name: 'HomeIndex',
  components: { Header, Category, Recommend, GoodsList },
  computed: {
    ...mapState({
      navActive: (state) => state.home.navActive,
      allCategoryList: (state) => state.home.allCategoryList,
      userInfo: (state) => state.user.userInfo,
      userPosition: (state) => state.user.userPosition,
      userInit: (state) => state.user.userInit,
      detailInfo: (state) => state.detail.detailInfo,
      vipShowFlag: (state) => state.detail.vipShowFlag,
      errorInfo: (state) => state.detail.errorInfo,
    }),
  },
  data() {
    return {
      init: false,
      recommendList: null,
      visitTimesList: null,
      showDialog: false,
      // springFestivalVisible: true,
    }
  },
  watch: {
    allCategoryList: {
      handler: function (newVal, oldVale) {
        this.getRecommendList()
      },
      immediate: true,
    },
    userPosition: {
      handler: function (newVal, oldVale) {
        this.init = false
        this.getRecommendList()
      },
      immediate: true,
    },
    userInfo:{
      handler: function (newVal, oldVale) {
        if(newVal){
          this.setGoodsInfoReset();
        }
      },
    }
  },
  created() {
    // 获取用户信息
    this.getUserInfo()
  },
  mounted() {
    if(this.vipShowFlag.setVipShowFlag == true){
      this.setVipShow(1)
    }
    if(this.errorInfo.errorInfo == true){
      this.$message({ type: 'info', message: this.errorInfo.message });
    }
  },
  methods: {
    ...mapMutations('home', ['setNavActive']),
    ...mapActions('user', ['getUserInfo']),
    ...mapMutations('detail',['setGoodsInfoReset']),
    // 获取推荐列表
    getRecommendList() {
      if (!this.allCategoryList || !this.userPosition.location || this.init) {

        return
      }
      this.init = true
      this.recommendList = {}
      this.visitTimesList = {}
      this.allCategoryList.forEach((item) => {
        if(item.value != "其他"){
          apiRecommendList({
            userUuid: this.userInfo.userUuid,
            materialType: item.key,
            lon: this.userPosition.location.lng,
            lat: this.userPosition.location.lat,
            page: 1,
            size: 5,
          }).then((res) => {
            this.$set(this.recommendList, item.key, res.data)
          })
        console.log('用户id============',this.userInfo.userUuid)
          apiRecommendList({
            userUuid: this.userInfo.userUuid,
            materialType: item.key,
            lon: this.userPosition.location.lng,
            lat: this.userPosition.location.lat,
            page: 1,
            size: 5,
            sortType: 1,
          }).then((res) => {
            this.$set(this.visitTimesList, item.key, res.data)
          })
        }
      })
    },
    // 返回首页
    toHome() {
      this.$router.push('/')
      this.setNavActive(0)
    },
    setVipShow(data) {
      if (data == '1') {
          this.showDialog = true
      }
    },
    setShowDialog(data) {
      this.showDialog = data
    },
    // //新年气氛关闭
    // closeNewyear() {
    //   this.springFestivalVisible = false;
    // }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  .page-content {
    width: $contentWidth;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .homeIndex{
      margin-top: 64px;
    }
  }
}
//新年氛围
//#newyear {
//  position: fixed;
//  bottom: 0;
//  width: 100%;
//  z-index: 9998;
//
//  .close {
//    position: absolute;
//    top: -24%;
//    right: 7%;
//    cursor: pointer;
//    width: 30px;
//    height: 30px;
//  }
//}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.customerPhone{
  margin-bottom: 10px;
}
</style>
