<template>
  <div class="page-container">
    <div class="page-content">
      <UserInfoTop />
      <div class="ui-page-content">
        <div style="flex: 1;">
          <img class="page-content-left-box" src="@/assets/images/public/icon-login-left-image.png" />
        </div>
        <div style="flex: 1; justify-content: right; display: flex">
          <div class="page-content-right-box">
            <div class="page-content-right-child-box">
              <div class="page-content-right-child-top">
                <p class="p-welcome">欢迎注册</p>
                <!-- <img src="@/assets/images/public/icon_login_sign_now.png" @click="handleLogin" style="cursor: pointer;width: 93px;"/> -->
              </div>
              <el-form class="el-form-content" label-width="90px" :rules="dataRules" :model="registerData">
                <el-form-item label="用户名称" class="el-item-text">
                  <el-input v-model="registerData.userName" placeholder="请输入用户名称"> </el-input>
                  <!-- <p class="el-icon-warning-outline el-item-text-tips">支持中文、英文、数字、“-”、“_”的组合，4-20个字符</p> -->
                </el-form-item>
                <el-form-item label="注册手机号" class="el-item-text" style="margin-top: 8px">
                  <el-input placeholder="请输入注册手机号" v-model="registerData.telephone" type="number" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)" onkeyup="this.value = this.value.replace(/[^\d]/g,'');">
                    <el-button slot="suffix" type="text" class="el-item-e1251b el-item-button" :disabled="countdown > 0" @click="startTimer">{{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}</el-button>
                  </el-input>
                </el-form-item>
                <el-form-item label="短信验证码" class="el-item-text">
                  <el-input placeholder="请输入短信验证码" v-model="registerData.code" type="number" maxlength="6" oninput="if(value.length>6)value=value.slice(0,6)"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" class="el-item-text" prop="password">
                  <el-input placeholder="请输入登录密码密码长度为8位" show-password v-model="registerData.password"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" class="el-item-text" prop="q_password">
                  <el-input placeholder="请再次输入登录密码" show-password v-model="registerData.q_password">
                    <!-- <el-button slot="suffix" type="text" icon="el-icon-view"></el-button> -->
                  </el-input>
                </el-form-item>
                <el-form-item label="用户身份" class="el-item-text">
                  <el-checkbox-group v-model="registerData.typeStatus" class="el-item-cgroup" @change="handleTypeStatusChange">
                    <el-checkbox label="服务商家"></el-checkbox>
                    <el-checkbox label="施工单位"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-button class="el-item-button2" @click="handleUserRegister">立即注册</el-button>
                </el-form-item>
                <el-form-item label-width="0" class="el-item-check-agreement-label">
                  <div class="el-item-check-agreement">
                    <el-checkbox v-model="isAgreement">我已阅读并同意</el-checkbox>
                    <a href="https://file.jupai.net/jupai/jlease/pcyxAgreement.html" target="agreement">《每日循环用户注册协议》</a>
                    及
                    <a href="https://file.jupai.net/jupai/jlease/pcPrivacypolicy.html" target="private">《隐私政策》</a>
                  </div>
                </el-form-item>
              </el-form>
              <div class="right-skip-register" @click="handleLogin">去登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import UserInfoTop from '.././login/components/UserInfoTop.vue'
import { registerNewUser } from '@/api/user'
import { sendSMS } from '@/api/common'
import { setToken } from '@/utils/cookie.js'
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'RegisterIndex',
  components: { UserInfoTop },
  computed: {},
  data() {
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('密码不能为空'))
      } else if (value != this.registerData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      isSelPassword: false,
      isAgreement: false,
      countdown: 0,
      registerData: {
        telephone: '',
        password: '',
        q_password: '',
        code: '',
        userName: '',
        serStatus: '', // 服务商标识 0否，1是
        entStatus: '', // 施工企业标识
        typeStatus: [], // 选择服务商及施工商类型
      },
      dataRules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
        q_password: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    handleSmsLogin() {
      this.isSelPassword = false
      console.log(this.isSelPassword)
    },
    handlePasswordLogin() {
      this.isSelPassword = true
      console.log(this.isSelPassword)
    },
    // 免费注册
    handleSkipRegister() {
      this.$router.push('/')
    },
    // 忘记密码
    handleForgetPassword() {
      this.$router.push('/')
    },
    handleUserRegister() {
      // 注册新用户
      if (!this.registerData.userName) {
        this.$message('用户名称不能为空')
      } else if (!this.registerData.telephone) {
        this.$message('手机号不能为空')
      } else if (!this.registerData.code) {
        this.$message('验证码不能为空')
      } else if (!this.registerData.password) {
        this.$message('密码不能为空')
      } else if (!this.registerData.q_password) {
        this.$message('确认密码不能为空')
      } else if (this.registerData.q_password !== this.registerData.password) {
        this.$message('两次密码不一致')
      } else if (this.registerData.typeStatus == 0) {
        this.$message('请选择用户身份')
      } else if (!this.isAgreement) {
        this.$message('请仔细阅读并勾选协议')
      } else {
        registerNewUser({
          telephone: this.registerData.telephone,
          password: this.registerData.password,
          code: this.registerData.code,
          userName: this.registerData.userName,
          serStatus: this.registerData.serStatus,
          entStatus: this.registerData.entStatus,
        }).then((res) => {
          var data = res
          if (data.success) {
            let userUuid = data.data.userUuid
            setToken(userUuid)
            this.getUserInfo().then((res) => {
              this.$router.push('/')
            })
          } else {
            this.$message(data.msg)
          }
        })
      }
    },
    handleTypeStatusChange() {
      if (this.registerData.typeStatus.indexOf('服务商家') != -1) {
        this.registerData.serStatus = 1
      } else {
        this.registerData.serStatus = 0
      }
      if (this.registerData.typeStatus.indexOf('施工单位') != -1) {
        this.registerData.entStatus = 1
      } else {
        this.registerData.entStatus = 0
      }
    },
    startTimer() {
      // 倒计时
      if (!this.registerData.telephone || this.registerData.telephone == '') {
        this.$message('请输入手机号！')
        return
      }
      var data = {
        smsType: '1',
        telephone: this.registerData.telephone,
      }
      sendSMS(data).then((res) => {
        console.log(res)
        if (res.code == '000000') {
          //获取验证码成功
          this.countdown = 59
          const interval = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--
            } else {
              clearInterval(interval)
            }
          }, 1000)
        } else {
          this.$message(res.msg)
        }
      })
    },
    handleLogin() {
      this.$router.push('/login')
    }
  },
}
</script>
    
    <style lang="scss" scoped>
@import '@/styles/variables';

.page-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  .page-content {
    width: 100%;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    background-image: url('@/assets/images/public/icon-login-background.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 100% 100%;

    // background: url('@/assets/images/public/icon-login-background.png') no-repeat #f05a19;
    .ui-page-content {
      width: $contentWidth;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
.page-content-left-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 748px;
  height: 487px;
  margin-left: -97px;
}
.page-content-right-box {
  background: white;
  width: 429px;
  height: 584px;
  border-radius: 16px;
  margin-top: 21px;
  margin-bottom: 50px;

  .page-content-right-child-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .page-content-right-child-top {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 56px 0 22px;
      .p-welcome {
        font-size: 24px;
        color: $primaryTextColor;
        font-weight: bold;
      }
    }
  }
}

.el-form-content {
  width: 100%;
  padding-left: 56px;
  padding-right: 56px;
  .el-item-text-tips {
    font-size: 12px;
    text-align: right;
    width: 100%;
  }
  .el-item-text-r {
    margin-right: 61px;
  }
  .el-item-text {
    margin-top: 20px;
    color: $secondaryTextColor;
  }
  .el-item-button {
    color: $mainColor;
    padding: 13px 0;
    &:hover {
      color: $mainColor;
    }
    &:focus {
      color: $mainColor;
    }
  }
  .el-item-button2 {
    width: 100%;
    height: 40px;
    margin-top: 28px;
    background: $mainColor;
    color: white;
    &:hover {
      color: white;
      border-color: transparent;
    }
    &:focus {
      color: white;
      border-color: transparent;
    }
  }
}
.right-skip-register{
  font-size: 14px;
  color: $mainColor;
  line-height: 19px;
  margin-top: 21px;
  cursor: pointer;
}
::v-deep .el-checkbox .el-checkbox__inner {
  border-radius: 50% !important;
  overflow: hidden;
  border-color: #ececec;
  &:hover {
    border-color: $mainColor;
  }
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $mainColor;
  border-color: $mainColor;
}
::v-deep .el-checkbox .is-checked .is-focus .el-checkbox__inner {
  background-color: $mainColor;
  border-color: $mainColor;
}

::v-deep .el-checkbox .el-checkbox__label {
  font-size: 12px;
  color: #bbbbbb;
}
::v-deep .el-checkbox .is-checked + .el-checkbox__label {
  color: #bbbbbb;
}
:v-deep .el-button:hover, .el-button:focus{
  border-color: $mainColor;
}
::v-deep .el-form-item__label {
  font-size: 14px;
  color: $color1f;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
  background: $colorF5;
  border-radius: 0;
  border: none;
  padding: 0 8px;
}
::v-deep .el-input__suffix {
  right: 15px;
}
.el-item-cgroup ::v-deep .el-checkbox__inner {
  border-radius: 10% !important;
}

.el-item-cgroup ::v-deep .el-checkbox__label {
  font-size: 12px;
  color: $color1f;
  margin-top: 0px;
}
.el-item-cgroup ::v-deep .is-checked + .el-checkbox__label {
  color: #333333;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
.el-item-check-agreement {
  display: flex;
  color: #bbbbbb;
  font-size: 12px;
  text-wrap: nowrap;
  margin-top: 16px;
  align-items: center;
}
.el-item-check-agreement-label ::v-deep .el-form-item__content {
  line-height: 15px;
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
    