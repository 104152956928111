<template>
  <div class="component-container">
    <div class="component-content">
      <!-- <div class="box top-box">
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-1.png" class="img-icon" />
          </div>
          <p class="text">海量信息 真实有效</p>
        </div>
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-2.png" class="img-icon" />
          </div>
          <p class="text">专业可靠 安全舒心</p>
        </div>
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-3.png" class="img-icon" />
          </div>
          <p class="text">多仓直发 快速高效</p>
        </div>
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/icon-4.png" class="img-icon" />
          </div>
          <p class="text">阳光租赁 一站服务</p>
        </div>
      </div> -->
      <div class="box top-box">
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/footer/shareIcon.png" class="share-icon" />
          </div>
        </div>
        <div class="item">
          <div class="firstLevelTitle">关于我们</div>
          <ul class="subtitle">
            <li @click='toIntroduction("brief")'>公司简介</li>
            <li @click='toIntroduction("honor")'>公司荣誉</li>
<!--            <li>意见反馈</li>-->
          </ul>
        </div>
        <div class="item">
          <div class="firstLevelTitle">联系我们</div>
          <ul class="subtitle">
            <li>电话：400-658-1699</li>  
            <li>邮箱：13393118881@189.cn</li>
            <li>地址：河北省石家庄市长安区中山东路11号乐汇城1-3-705</li>
          </ul>
        </div>
        <div class="item">
          <div class="icon-box">
            <img src="@/assets/images/public/xhmini.png" class="img-wxmini" />
            <p class="p-wxsweep">扫码关注小程序</p>
          </div>
        </div>
      </div>
      <div class="box bottom-box">
        违法和不良信息举报邮箱：jianchabu@feiiiu.net | 互联网举报中心<br />
        冀公网安备 13010202001700号 Copyright© 2008 JuPai.Net Inc.聚拍网 版权所有增值电信业务经营许可证 <a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2024057668号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeFooter',
  components: {},
  methods:{
    toIntroduction(type){
      this.$router.push({ path: `/introduction/${type}` });
    }
  }
}
</script>
<style lang="scss" scoped>
.component-content {
  .top-box {
    width: 100%;
    height: 179px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 28px;
      margin-right: 100px;
      &:last-child {
        margin-right: 0;
      }
      .firstLevelTitle{
        color: #1F1F1F;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
      }
      .subtitle{
        li{
          color: #A6A6A6;
          font-size: 12px;
          line-height: 30px;
          cursor: pointer;
        }
      }
      .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
        .img-icon {
          width: 80px;
        }
        .share-icon{
          width: 231px;
          margin-top: 39px;
        }
        .img-wxmini{
          width: 94px;
          height: 94px;
        }
        .p-wxsweep{
          font-size: 12px;
          color: #A6A6A6;
          line-height: 16px;
          margin-top: 8px;
        }
      }
    }
  }
  .bottom-box {
    // display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 80px;
    line-height: 16px;
    background: #0E131D;
    font-size: 12px;
    color: #4C4C4C;
    padding-top: 24px;
    box-sizing: border-box;
    a {
      display: inline-block;
    }
  }
}
</style>
