<template>
  <div class='page-container'>
    <Header @home='toHome' />
    <div class='list'>
      <div class="item-content-list">
        <div v-for="(item, index) in selTitleData" :key="index + 'w'" class="item-content-list-text" :class="{ active: selTitleIndex === index }" @click="handleNavClick(index)">
          {{ item }}
        </div>
      </div>
      <el-divider></el-divider>
      <Brief v-if='selTitleIndex==0' ></Brief>
      <Honor v-if='selTitleIndex==1' ></Honor>
    </div>


  </div>
</template>

<script>
import Header from '@/components/public/Header.vue'
import Brief from '@/views/introduction/components/Brief'
import Honor from '@/views/introduction/components/Honor'

export default {
  name: 'briefIntroduction',
  components: { Header ,Brief ,Honor},
  data() {
    return {
      activeName: 'second',
      selTitleData: ['公司简介 ', '公司荣誉'],
      selTitleIndex: 0,
    }
  },
  watch: {
    '$route.params': {
      handler: function(newVal, oldVal) {
        if (!newVal){
          return
        }
        if (newVal.brief.includes('brief')){
          this.selTitleIndex=0
        }else {
          this.selTitleIndex=1
        }
      }
    }
  },
  mounted() {
    this.selTitleIndex=this.$route.params.brief.includes('brief')?0:1
  },
  methods: {
    // 返回首页
    toHome() {
      this.$router.push('/')
      this.setNavActive(0)
    },
    handleNavClick(index) {
      this.selTitleIndex=index
      this.$router.push({ path: `/introduction/${index==0?"brief":"honor"}` });
    },
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/variables';
.list {
  width: $contentWidth;
  margin-top: 90px;
  border-radius: 16px;
  background: #FFFFFF;
  margin-bottom: 20px;
}

.item-content-list {
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 57px;
  .item-content-list-text {
    color: #333333;
    margin-right: 73px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $mainColor;
    }
    &.active {
      color: $mainColor;
    }
  }
}
::v-deep .el-divider--horizontal {
  margin: 0px 20px 0px 20px;
  background-color: #eaeaea;
  width: auto;
}
</style>