<template>
  <div class="component-container recommend">
    <div class="component-content recommend">
      <img src="@/assets/images/home/title-2.png" class="img-title" />
      <div class="category" v-for="(item, index1) in allCategoryList" :key="item.key" v-show="allCategoryList.length > 0">
        <div class="title-box" v-if="list && list[item.key] && list[item.key].length > 0">
          <p class="title">{{ item.value }}</p>
          <p class="sub-title">{{ subTitleList[item.key]["subTitle"] }}</p>
          <div v-if="item.children" class="list">
            <div class="item secondary-text" v-for="(subItem, index2) in item.children.slice(0, 7)" :key="subItem.name" @click="handleClick(index1, index2, -1)">
              <p class="text">{{ subItem.name }}</p>
            </div>
          </div>
        </div>
        <template v-if="list[item.key]">
          <GoodsItem v-for="item2 in list[item.key].slice(0, 4)" :key="item2.infoNo" @set-vip-show ="setVipShow" class="goods-item" :data="item2" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import GoodsItem from './GoodsItem'

export default {
  name: 'HomeCategory',
  components: { GoodsItem },
  props: {
    list: Object,
  },
  data() {
    return {
      subTitleList: {
        "1":{ title: '周转物资', subTitle: 'Turnover materials' },
        "2":{ title: '机械设备', subTitle: 'Mechanical equipment' },
        "4":{ title: '原材料', subTitle: 'Raw material' },
        "5":{ title: '电缆', subTitle: 'Cable' },
        "6":{ title: '木材', subTitle: 'Wood' },
        "7":{ title: '活动房屋', subTitle: 'Mobile housing' },
        "8":{ title: '轨道结构', subTitle: 'Track structure' },
      },
    }
  },
  computed: {
    ...mapState({
      navActive: (state) => state.home.navActive,
      allCategoryList: (state) => state.home.allCategoryList,
    }),
  },
  methods: {
    ...mapMutations('home', ['setNavActive', 'setAllCategoryListClickIndex']),
    // 点击-分类
    handleClick(index1, index2, index3) {
      this.$router.push('/home/hire')
      this.setNavActive(1)
      this.setAllCategoryListClickIndex([index1, index2, index3])
      this.$bus.$emit('clickAllCategory', [index1, index2, index3])
    },
    setVipShow(data){
      console.log('点击了2')
      this.$emit('set-vip-show-top',data);
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.component-container.recommend {
  margin-top: 20px;
  .component-content.recommend {
    width: $contentWidth;
  }
}
.img-title {
  width: 176px;
  margin-bottom: 10px;
}
.category {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $colorWhite;
  border-radius: 16px;
  padding:10px;
  .title-box {
    width: 248px;
    height: 385px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 16px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 232px;
      height: 2px;
    }

    .title {
      font-size: 32px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      line-height: 42px;
    }
    .sub-title {
      margin-top: 2px;
      font-size: 13px;
      font-weight: 300;
    }
    .list {
      width: 100%;
      margin-top: 40px;
      padding: 0 15px;
      .item {
        display: inline-block;
        cursor: pointer;
        overflow: hidden;
        max-width: 91px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #dddddd;
        padding: 6px 9px 7px 10px;
        margin: 0 5px 10px;
        p{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:hover {
          padding: 7px 10px 8px 11px;
          border: none;
        }
      }
    }
  }
  &:nth-child(2) {
    .title-box {
      background: linear-gradient(180deg, rgba(221, 223, 238, 0.49) 0%, #ffffff 100%);
      // &::after {
      //   background: linear-gradient(270deg, #ffffff 0%, #536aa4 100%);
      // }

      .title {
        color: #536aa4;
      }
      .sub-title {
        background: linear-gradient(180deg, #536aa4 0%, rgba(216, 216, 216, 0.18) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .list {
        .item {
          &:hover {
            background: #536AA4;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  &:nth-child(3) {
    .title-box {
      background: linear-gradient(180deg, #ddebee 0%, #ffffff 100%);
      // &::after {
      //   background: linear-gradient(270deg, #ffffff 0%, #68a4b1 100%);
      // }
      .title {
        color: #68a4b1;
      }
      .sub-title {
        background: linear-gradient(180deg, #498d9c 0%, rgba(216, 216, 216, 0.18) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .list{
        .item{
          &:hover{
            background: #68A4B1;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  &:nth-child(4) {
    .title-box {
      background: linear-gradient(180deg, #e5dced 0%, #ffffff 100%);
      // &::after {
      //   background: linear-gradient(270deg, #ffffff 0%, #7a53a4 100%);
      // }
      .title {
        color: #7a53a4;
      }
      .sub-title {
        background: linear-gradient(180deg, #7a53a4 0%, rgba(216, 216, 216, 0.18) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .list{
        .item{
          &:hover{
            background: #7A53A4;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  &:nth-child(5) {
    .title-box {
      background: linear-gradient(180deg, #ddede3 0%, #ffffff 100%);
      // &::after {
      //   background: linear-gradient(270deg, #ffffff 0%, #68b182 100%);
      // }
      .title {
        color: #68b182;
      }
      .sub-title {
        background: linear-gradient(180deg, #68b182 0%, rgba(216, 216, 216, 0.18) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .list{
        .item{
          &:hover{
            background: #68B182;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  &:nth-child(6) {
    .title-box {
      background: linear-gradient(180deg, #efebde 0%, #ffffff 100%);

      // &::after {
      //   background: linear-gradient(270deg, #ffffff 0%, #a49353 100%);
      // }
      .title {
        color: #a49353;
      }
      .sub-title {
        background: linear-gradient(180deg, #a49353 0%, rgba(216, 216, 216, 0.18) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .list{
        .item{
          &:hover{
            background: #A49353;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  &:nth-child(7) {
    .title-box {
      background: linear-gradient(180deg, #ebd9d9 0%, #ffffff 100%);
      // &::after {
      //   background: linear-gradient(270deg, #ffffff 0%, #b16868 100%);
      // }
      .title {
        color: #b16868;
      }
      .sub-title {
        background: linear-gradient(180deg, #b16868 0%, rgba(216, 216, 216, 0.18) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .list{
        .item{
          &:hover{
            background: #B16868;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  &:last-child {
    .title-box {
      background: linear-gradient(180deg, #e9ded6 0%, #ffffff 100%);
      // &::after {
      //   background: linear-gradient(270deg, #ffffff 0%, #a47453 100%);
      // }
      .title {
        color: #a47453;
      }
      .sub-title {
        background: linear-gradient(180deg, #a47453 0%, rgba(216, 216, 216, 0.18) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .list{
        .item{
          &:hover{
            background: #A47453;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .goods-item {
    margin-left: 10px;
  }
}
.el-empty {
  width: 100%;
  margin-left: 10px;
}
</style>
