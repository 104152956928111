import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import Publish from '../views/publish/index.vue'
import QuickPublish from '../views/quickPublish/index.vue'
import UploadFile from '../views/uploadFile/index.vue'
import LoginIndex from '../views/login/index.vue'
import RegisterIndex from '../views/register/index.vue'
import MessageIndex from '../views/personalcenter/message/index.vue'
import RealNameCompany from '../views/accountManage/realName/company/index.vue'
import RealNameCompanyBase from '../views/accountManage/realName/company/baseInfoIndex.vue'

import PHomeIndex from '../views/personalcenter/home/index.vue'
import PDetailIndex from '../views/productdetail/index.vue'
import RealNameReuslt from '../views/accountManage/realName/realNameResult/index.vue'
import Demo from '../views/demo/index.vue'
import ChangePassword from '../views/accountManage/changePassword/index.vue'
import ChangeInfo from '../views/accountManage/changeInfo/index.vue'
import AccountManage from '../views/accountManage/index.vue'
import RelaName from '../views/accountManage/realName/index.vue'
import introduction from '../views/introduction/index.vue'

// 仓储
import Storage from '../views/storage/index.vue';
import { getToken } from '@/utils/cookie'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const originalReplace = VueRouter.prototype.replace
//修改原型对象中的push方法
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/', // 首页
    name: 'home',
    component: Home,
  },
  {
    path: '/home/:nav',
    name: 'home',
    component: Home,
  },
  {
    path: '/publish', // 发布页
    name: 'publish',
    component: Publish,
  },
  {
    path: '/publish/:type',
    name: 'publish',
    component: Publish,
  },
  {
    path: '/publish/:type/:infoNo', // 发布信息编辑，需要传发布的类型和infoN0 1: '出租', 2: '出售', 3: '求租', 4: '求购',
    name: 'publish',
    component: Publish,
  },
  {
    path: '/uploadView',
    name: 'uploadView',
    component: () => import('../views/uploadView.vue'),
  },
  {
    path: '/loginView',
    name: 'loginView',
    component: () => import('../views/loginView.vue'),
  },
  {
    path: '/uploadFile',
    name: 'uploadFile',
    component: UploadFile,
  },
  {
    path: '/quickPublish',
    name: 'quickPublish',
    component: QuickPublish,
  },
  {
    path: '/quickPublish/:id', // 快速发布编辑,
    name: 'quickPublish',
    component: QuickPublish,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginIndex,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterIndex,
  },
  {
    path: '/personal/message',
    name: 'message',
    component: MessageIndex,
  },
  {
    path: '/realName/company',
    name: 'realNameCompany',
    component: RealNameCompany,
  },
  {
    path: '/realName/company/baseInfoIndex',
    name: 'realNameCompanyBase',
    component: RealNameCompanyBase,
  },

  {
    path: '/personal/home',
    name: 'phome',
    component: PHomeIndex,
  },
  {
    path: '/product/detail',
    name: 'detail',
    component: PDetailIndex,
  },
  {
    path: '/realName/realNameReuslt',
    name: 'realNameReuslt',
    component: RealNameReuslt,
  },
  {
    path: '/demo',
    name: 'demo',
    component: Demo,
  },
  {
    path: '/accountManage/changePassword',
    name: 'changePassword',
    component: ChangePassword,
  },
  {
    path: '/accountManage/changeInfo',
    name: 'changeInfo',
    component: ChangeInfo,
  },
  {
    path: '/accountManage',
    name: 'accountManage',
    component: AccountManage,
  },
  {
    path: '/accountManage/relaName',
    name: 'relaName',
    component: RelaName,
  },
  {
    path: '/storage', // 仓储
    name: 'storage',
    component: Storage,
  },
  {
    path: '/introduction/:brief',
    name: 'introduction',
    component: introduction
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
   // 解决路由跳转后，不显示在首行
  scrollBehavior() {
    return {x: 0, y: 0};
  }
})
// 路由白名单
const whiteList = ['login', 'register', 'home', 'changePassword', 'storage' , 'introduction']
router.beforeEach((to, from,next)=>{
  if(!getToken() && !whiteList.includes(to.name)){
    return next('/login')
  }
  next()
})
export default router
