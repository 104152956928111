<template>
  <div class="component-container">
    <div class="component-content">
      <img src="@/assets/images/public/logoMrxh.png" @click="handleClickHome" class="img-logo" />
      <div class="component-right">
        <img src="@/assets/images/public/icon-phoneF.png" class="img-phone" />
        <p class="p-phone">{{ phone }}</p>
        <!-- <div class="component-mini">
          <img src="@/assets/images/public/xhmini.png" class="img-wxmini" />
          <p class="p-wxsweep">扫一扫进入小程序</p>
        </div> -->
      </div>
    </div>
  </div>
</template>
  
<script>
import { apiGetSysConfig } from '@/api/index'
export default {
  name: 'HomeVIP',
  components: {},
  data() {
    return {
      phone: '',
      showDialog: false,
    }
  },
  mounted() {
    // 获取顶部电话
    apiGetSysConfig({ code: 'jzl_customer_service_phone' }).then((res) => {
      this.phone = res.data
    })
  },
  methods: {
    handleClickHome() {
      this.$router.push('/')
    },
  },
}
</script>
  
<style lang="scss" scoped>
@import '@/styles/variables';

.component-content {
  width: $contentWidth;
  padding: 18px 0 113px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.img-logo {
  cursor: pointer;
  width: 151px;

  &:hover {
    opacity: 0.9;
  }
}
.component-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  .p-phone {
    font-size: 22px;
    color: $colorWhite;
    font-weight: bold;
  }
  .img-phone {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
}
.component-mini {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img-wxmini {
    width: 60px;
    height: 60px;
  }

  .p-wxsweep {
    font-size: 12px;
    color: #999999;
    margin-top: 3px;
  }
}
</style>
  