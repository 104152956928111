<template>
    <div class="index-box" :style='{height:screenHeight}'>
      <div style='position: absolute;display: flex;justify-content: center;width: -webkit-fill-available' v-if='$route.path!=="/accountManage"'>
        <img class="topBanner" src="@/assets/images/realname/accountTopBanner.png" >
      </div>
      <img class="topBanner1" src="@/assets/images/realname/accountTopBanner.png" v-else>
        <div class="step-box">
            <div class="step" :class="{ active: step >= 1 }">
                <div class="num">1</div>
                <div class="title">身份验证</div>
            </div>
            <div class="step" :class="{ active: step >= 2 }">
                <div class="num">2</div>
                <div class="title">修改密码</div>
            </div>
            <div class="step" :class="{ active: step >= 3 }">
                <div class="num">3</div>
                <div class="title">修改成功</div>
            </div>
        </div>
        <PasswordStep1 v-if="step === 1" @step1Next="step1Next" />
        <PasswordStep2 :lastData="step1Data" v-if="step === 2" @step2Next="step2Next" />
        <PasswordStep3 v-if="step === 3" @step3Next="step3Next" />
    </div>
</template>
        
<script>

import PasswordStep1 from '@/views/accountManage/changePassword/components/passwordStep1.vue'
import PasswordStep2 from '@/views/accountManage/changePassword/components/passwordStep2.vue'
import PasswordStep3 from '@/views/accountManage/changePassword/components/passwordStep3.vue'


export default {
    name: 'changePassword',
    components: { PasswordStep1, PasswordStep2, PasswordStep3 },
    computed: {

    },
    data() {
        return {
            step: 1,
            step1Data: {
                "phone": '',
                "code": ''
            },
          screenHeight:'0px'
        }
    },
  mounted() {
    this.screenHeight = (window.innerHeight-259)+'px';
  },
  methods: {
        // 身份验证点击提交认证
        step1Next(data) {
            console.log(data);
            this.step1Data = data;
            this.step = 2
        },
        //第二部点击提交
        step2Next() {
            this.step = 3
        },
        //第三步点击提交
        step3Next() {

        }
    },
}
</script>
        
<style lang="scss" scoped>
@import '@/styles/variables';

.index-box {
    flex: 1;
    // width: 1060px;
    //height: 660px;
    margin-left: 10px;
    // margin-bottom: 10px;
    background-color: white;
    //  white;
}

.topBanner {
width: 1060px;
height: 154px;
}
.topBanner1 {
  width: 1060px;
  height: 154px;
  position: absolute;
}
.step-box {
    width: 100%;
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    .step {
        display: flex;
        justify-content: center;
        align-items: center;

        .num {
            width: 36px;
            height: 36px;
            margin-right: 5px;
            background: #d8d8d8;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
        }

        .title {
            font-size: 12px;
            color: #333333;
        }

        &.active {
            .num {
                background: $importantColor;
            }
        }

        &:last-child {
            &:after {
                content: none;
            }
        }

        &:after {
            content: '';
            width: 100px;
            height: 1px;
            border-top: 1px dashed #999999;
            margin: 0 5px;
        }
    }
}
</style>
    