<template>
  <div class='component-container' :class='{ fixed: isNavFixed }'>
    <div class='component-content'>
      <div class='logo'>
        <img src='@/assets/images/public/logoMrxh.png' class='icon-logo' v-if='isNavFixed == false'
             @click="$emit('home')" />
        <img src='@/assets/images/public/logoMrxu1.png' class='icon-logo' v-if='isNavFixed == true'
             @click="$emit('home')" />
      </div>
      <div class='list' v-if='!isSearch'>
        <div style='display: flex;justify-content: space-between;flex: 1'>
          <div class='classify' v-if='isNavFixed == true' @mouseenter='showCategoryPopup=true' @mouseleave='showCategoryPopup=false'>
            <p class='text-classify'>全部分类</p>
            <p class='icon-down'></p>
            <div class="line line-2 popup-category" v-if='showCategoryPopup'>
              <AllCategoryPop class="all-catrgory" :is-home='false'/>
            </div>
          </div>
          <div class='item' v-for='(item, index) in navList' :class='{ active: navActive === index }' :key='item.value'
               @click='handleNavClick(index)'>
            {{ item.name }}
          </div>
        </div>

        <div class='location' @mouseenter='handleLocationMouseEnter' @mouseleave='handleLocationMouseLeave'>
          <i class='icon-location'></i>
          <p class='text-location'>{{ userPosition && userPosition.name }}</p>
          <p class='icon-down'></p>
          <div v-if='showLocationPopup' class='popup-box locationLayer'>
            <el-tabs v-model='active' @tab-click='handleClick'>
              <el-tab-pane v-for='(item, index) in cityList' :key='item.title + index' :label='item.title'
                           :name='item.title'>
                <div class='list' v-if='item.children'>
                  <div class='item' v-for='(subItem, index) in item.children' @click='handlePostionClick(subItem)'
                       :key='subItem.id + index'>{{ subItem.name }}
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <el-popover
          placement='bottom-start'
          width='240'
          trigger='hover'
          :visible-arrow='false'
          popper-class='personalStyle'>
          <div class='personal-center'>
            <div class='avatar'>
              <img :src="userInfo.headImg || require('@/assets/images/public/avatar.png')" class='img-avatar' />
            </div>
            <div class='list1'>
              <div class='item' :class='{ active: userInfo.entStatus === 3 }'>
                <i class='img-icon img-icon-2' />
                <p class='text'>施工单位</p>
              </div>
              <div class='item' :class='{ active: userInfo.serStatus === 3 }'>
                <i class='img-icon img-icon-3' />
                <p class='text'>服务商家</p>
              </div>
            </div>

            <p class='name' @click='toUserCenter'>Hi~ {{ userInfo.userName || userInfo.phoneHide }} 欢迎来到每日循环</p>

            <el-button class='button' type='primary' @click='toUserCenter' round>个人中心</el-button>

            <div class='button1' @click='handleLogout'>退出登录</div>
          </div>

          <div class='user login' v-if='userInfo.userUuid' slot='reference'>
            <div class='avatar'>
              <img :src="userInfo.headImg || require('@/assets/images/public/avatar.png')" class='img-avatar' />
            </div>
            <p class='name'>{{ userInfo.phoneHide }}</p>
            <p class='icon-down'></p>
          </div>
        </el-popover>

        <div class='login' v-if='!userInfo.userUuid'>
          <i class='icon-login'></i>
          <p class='button' @click='handleLogin'>登录</p>
          <span class='line'>|</span>
          <p class='button' @click='handleRegister'>注册</p>
        </div>
        <div class='search-box' @click='isSearch=true'>
          <input type='text' class='input' />
          <div class='search-input'>
            <i class='icon-search'></i>
            <div class='button' >搜索</div>
          </div>
        </div>

      </div>
      <div class='search-full' v-else>
        <input type='text' class='input' v-model='keywords' placeholder='请输入搜索的内容'/>
        <div class='search-input' @click='sreach()'>
          <i class='icon-search'></i>
          <div class='button' >搜索</div>
        </div>
        <i class="el-icon-close search-close" @click='isSearch=false'></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { JupaiUrl, NavList } from '@/constant/index'
import { all, hot } from '@/utils/city'
import { apiGetSysConfig } from '@/api/index'
import AMapLoader from '@amap/amap-jsapi-loader'
import AllCategoryPop from '@/components/public/AllCategoryPop'

export default {
  name: 'HomeNav',
  components: {AllCategoryPop },
  computed: {
    ...mapState({
      navActive: (state) => state.home.navActive,
      userInfo: (state) => state.user.userInfo,
      userPosition: (state) => state.user.userPosition
    })
  },
  data() {
    return {
      navList: NavList,
      isNavFixed: false,
      isHome: false,
      showLocationPopup: false,
      showCategoryPopup:false,
      showMiniPopup: false,
      active: '热门城市',
      isSearch: false,
      keywords:"",
      cityList: [
        { title: '热门城市', children: [] },
        { title: 'ABCDE', children: [] },
        { title: 'FGHIJ', children: [] },
        { title: 'KLMNO', children: [] },
        { title: 'PQRST', children: [] },
        { title: 'UVWXY', children: [] },
        { title: 'Z', children: [] }
      ]
    }
  },
  watch: {
    '$route.path': {
      handler: function(newVal, oldVal) {
        console.log(newVal)
        if (newVal.startsWith('/home') || newVal === '/') {
          this.isHome = true
          let index = this.navList.findIndex((item) => {
            return item.value === newVal.split('/')[2]
          })
          if (index === -1) {
            index = 0
          }
          if (newVal == '/storage') {
            index = 6
          }
          if (index === 0) {
            this.isNavFixed = false
          } else {
            this.isNavFixed = true
          }
          this.setNavActive(index)
        } else {
          this.isHome = false
          this.isNavFixed = true
        }

      },
      immediate: true
    }
  },
  created() {
    this.cityList.forEach((item) => {
      if (item.title === '热门城市') {
        item.children = hot
      } else {
        item.children = []
        item.title.split('').forEach((item2) => {
          if (all[item2]) {
            item.children = item.children.concat(all[item2])
          }
        })
      }
    })
    if (!this.userPosition.fullname) {
      let _this = this
      apiGetSysConfig({ code: 'jzl_map_config' }).then((res) => {
        const { key, secret } = res.data
        window._AMapSecurityConfig = {
          securityJsCode: secret
        }
        AMapLoader.load({
          key: key, // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.CitySearch'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          let citySearch = new AMap.CitySearch()
          citySearch.getLocalCity(function(status, result) {
            if (status === 'complete' && result.info === 'OK') {
              // 查询成功，result即为当前所在城市信息
              console.log(result)
              let adcode = result.adcode
              console.log(adcode)
              let flag = false
              for (let group in all) {
                for (let i = 0; i < all[group].length; i++) {
                  if (adcode == all[group][i]['id']) {
                    _this.setUserPosition(all[group][i])
                    flag = true
                    break
                  }
                }
                if (flag) {
                  break
                }
              }
            }
          })
        })
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('click', this.hideDiv);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideDiv);
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapMutations('home', ['setNavActive']),
    ...mapMutations('user', ['setUserPosition']),
    hideDiv(event) {
      if (!event.target.closest('.classify')) {
        this.showCategoryPopup = false;
      }
    },
    sreach(){
      this.$emit('set-sreach', this.keywords)
    },
    handleClick() {
    },
    handleNavClick(index) {
      if (index === 6) {
        this.$router.push('/storage')
        return
      }
      if (index === 7) {
        window.open(JupaiUrl)
        return
      }
      this.$router.push('/home/' + this.navList[index].value)
      this.setNavActive(index)
    },
    handleScroll() {
      const distanceFromTop = window.scrollY
      if (distanceFromTop > 70) {
        this.isNavFixed = true
      } else {
        if (this.navActive > 0 || !this.isHome) {
          this.isNavFixed = true
        } else {
          this.isNavFixed = false
        }
      }
    },
    // 鼠标移入-定位
    handleLocationMouseEnter() {
      this.showLocationPopup = true
    },
    // 鼠标移出-定位
    handleLocationMouseLeave() {
      this.showLocationPopup = false
    },
    // 点击选择城市
    handlePostionClick(pos) {
      this.showLocationPopup = false
      this.setUserPosition(pos)
    },
    // 登录
    handleLogin() {
      this.$router.push('/login')
    },
    // 退出
    handleLogout() {
      this.logout()
    },
    // 注册
    handleRegister() {
      this.$router.push('/register')
    },
    // 个人中心
    toUserCenter() {
      console.log('ppppppppppppppp')
      this.$router.push('/personal/home')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/variables';

.component-container {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.94) 0%, rgba(0, 0, 0, 0) 100%);
  //opacity: 0.8;

  .component-content {
    width: $contentWidth;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.logo {
  width: 276px;
  .icon-logo {
    width: 152px;
    cursor: pointer;
  }
}

.list {
 flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .item {
    line-height: 30px;
    cursor: pointer;
    font-size: $primaryTextSize;
    color: $colorWhite;

    &:hover {
      color: $colorWhite;
    }

    &.active {
      color: $colorWhite;
    }
  }

  .location {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
    padding: 0 8px;
    margin-left: 20px;
    line-height: 30px;
    font-size: 14px;
    cursor: pointer;

    .icon-location {
      width: 16px;
      height: 20px;
      background: url('@/assets/images/public/icon-position-white.png') no-repeat center / 100%;
      margin-right: 10px;
    }

    .text-location {
      color: $colorWhite;
    }

    position: relative;
  }

  .icon-down {
    width: 10px;
    border: 5px solid transparent;
    border-top-color: $colorWhite;
    margin: 6px 0 0 5px;
  }

  .user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .avatar {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      position: relative;

      .img-avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .name {
      margin-left: 8px;
      color: $colorWhite;
    }
  }


  .login {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0 40px 0 20px;

    .line {
      margin: 0 8px;
      color: $colorWhite;
    }

    .button {
      color: $colorWhite;
      cursor: pointer;
    }

    .icon-login {
      width: 18px;
      height: 20px;
      margin-right: 10px;
      background: url('@/assets/images/public/login-icon.png') no-repeat center / 100%;
    }
  }

  .search-box {
    position: relative;
    cursor: pointer;

    .input {
      width: 130px;
      height: 40px;
      background: $colorWhite;
      border-radius: 20px;
      opacity: 0.1;
      border: none;
    }

    .search-input {
      position: absolute;
      top: 9px;
      padding: 0 33px;
      display: flex;
      align-items: center;
      border: none;
      outline: none; /* 去除焦点时的轮廓 */
      box-shadow: none; /* 如果需要的话，去除输入框的阴影 */

      .icon-search {
        width: 22px;
        height: 22px;
        background: url('@/assets/images/public/search-icon.png') no-repeat center / 100%;
      }

      .button {
        margin-left: 14px;
        font-size: 14px;
        color: $colorWhite;
        line-height: 19px;
      }
    }
  }

}

.search-full {
  width: 80vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  .input {
    width: 60%;
    height: 40px;
    background: $colorWhite;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 0px 20px;
color: $color1f;
    border: none;
    outline: none; /* 去除焦点时的轮廓 */
    box-shadow: none; /* 如果需要的话，去除输入框的阴影 */
  }

  .search-input {
    height: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background: $mainColor;
    padding: 0 33px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none; /* 去除焦点时的轮廓 */
    box-shadow: none; /* 如果需要的话，去除输入框的阴影 */

    .icon-search {
      width: 22px;
      height: 22px;
      background: url('@/assets/images/public/search-icon.png') no-repeat center / 100%;
    }

    .button {
      margin-left: 14px;
      font-size: 14px;
      color: $colorWhite;
      line-height: 19px;
    }
  }
  .search-close{
    margin-left: 40px;
    color: $colorWhite;
    font-size: 20px;
    cursor: pointer;
  }
}
.popup-category{
  position: absolute;
  top: 50px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);

  .all-catrgory {
    height: 496px;
  }
}

.popup-box.locationLayer {
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 10;
  width: 354px;
  min-height: 160px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 16px;
  padding-top: 8px;

  .list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;

    .item {
      margin: 0 12px 10px;
      color: $primaryTextColor;
      font-size: 12px;
      cursor: pointer;
      text-align: center;

      &:hover {
        color: $mainColor;
      }
    }
  }
}

::v-deep .el-tabs__header {
  margin: 0;
  padding-left: 8px;
}

::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background: #f3f3f3;
}

::v-deep .el-tabs__item {
  height: 36px;
  padding: 0 5px;
  font-size: $secondaryTextSize;
  color: $secondaryTextColor;
  line-height: 36px;
}

::v-deep .el-tabs__item:hover {
  color: $primaryTextColor;
}

::v-deep .el-tabs__item.is-active {
  color: $primaryTextColor;
  font-weight: bold;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $colorWhite !important;
  opacity: 1;
  z-index: 999;

  .classify {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }

  .item {
    color: $color1f;
    text-align: center;

    &:hover {
      color: $color1f;
    }

    &.active {
      color: $mainColor;
    }
  }

  .location {
    cursor: pointer;
    margin-left: 20px;
    .icon-location {
      background: url('@/assets/images/public/icon-position-black.png') no-repeat center / 100%;
    }

    .text-location {
      color: $color1f;
    }
  }

  .icon-down {
    border-top-color: $color1f;
  }

  .user {
    .name {
      color: $color1f;
    }
  }

  .login {
    .line {
      color: $color1f;
    }

    .button {
      color: $color1f;
    }

    .icon-login {
      background: url('@/assets/images/public/login-icon-black.png') no-repeat center / 100%;
    }
  }

  .search-box {
    .input {
      background: $colorF5;
      opacity: 1;
      border: none;
    }

    .search-input {
      .icon-search {
        background: url('@/assets/images/public/search-icon-black.png') no-repeat center / 100%;
      }

      .button {
        color: $color66;
      }
    }
  }
  .search-full{
    .input {
      opacity: 1;
      color: $color1f;
      background: $colorF5;
    }
    .search-close{
      color: $color1f;
    }
  }
}
</style>
<style lang='scss'>
.el-popover.personalStyle {
  z-index: 10000;
  border-radius:16px;

  .personal-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: url('@/assets/images/home/icon_head_backgroud_personal.png') no-repeat center / 100%;
    background-size: cover;
border-radius: 13px;

    .avatar {
      width: 40px;
      height: 40px;
      margin-top: 20px;
      border-radius: 50%;
      position: relative;

      .img-avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .list1 {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        color: #e2a278;

        &:last-child {
          margin-right: 0;
        }

        .img-icon {
          margin-right: 3px;
          color: #e2a278;

          &.img-icon-1 {
            width: 10px;
            height: 12px;
            background: url('@/assets/images/home/icon-21.png') no-repeat center 100%;
            background-size: 11px;
          }

          &.img-icon-2 {
            width: 11px;
            height: 11px;
            background: url('@/assets/images/home/icon-22.png') no-repeat center 100%;
            background-size: 11px;
          }

          &.img-icon-3 {
            width: 11px;
            height: 11px;
            background: url('@/assets/images/home/icon-23.png') no-repeat center 100%;
            background-size: 11px;
          }
        }

        //&.active {
        //  color: #e2a278;
        //
        //  .img-icon {
        //    &.img-icon-1 {
        //      background: url('@/assets/images/home/icon-21.png') no-repeat center 100%;
        //      background-size: 11px;
        //    }
        //
        //    &.img-icon-2 {
        //      background: url('@/assets/images/home/icon-22.png') no-repeat center 100%;
        //      background-size: 11px;
        //    }
        //
        //    &.img-icon-3 {
        //      background: url('@/assets/images/home/icon-23.png') no-repeat center 100%;
        //      background-size: 11px;
        //    }
        //  }
        //}

      }
    }

    .name {
      margin-top: 12px;
      font-size: 12px;
    }

    .button {
      margin-top: 20px;
      cursor: pointer;
    }

    .button1 {
      margin-top: 10px;
      margin-bottom: 20px;
      cursor: pointer;
      color: #A6A6A6;
    }

  }


}
</style>
