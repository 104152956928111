<template>
  <div class="headerCon">
    <Banner/>
    <div class="component-container header">
    <Nav @home="toHome" @set-sreach='handleSearch'/>
    <div class="component-content header">
      <!-- <div class="line line-1">
        <img src="@/assets/images/public/logo.png" class="img-logo" @click="$emit('home')" />
        <div class="search-box">
          <div class="search-input">
            <input type="text" class="input" v-model="keywords" placeholder="请输入您想要搜索的关键词" />
            <div class="button" @click="handleSearch('')">搜索</div>
          </div>
          <div class="keywords">
            <p class="item secondary-text" v-for="item in keywordsList" :key="item" @click="handleSearch(item)">{{ item }}</p>
          </div>
        </div>
      </div> -->
      <div class="line line-2">
        <AllCategory class="all-catrgory" />
      </div>
    </div>
  </div>
  </div>
  
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import AllCategory from './AllCategory.vue'
import Nav from './Nav.vue'
import Banner from '@/views/home/components/Banner.vue';
export default {
  name: 'HomeHeader',
  components: { Nav, Banner, AllCategory },
  computed: {
    ...mapState({
      searchKeywords: (state) => state.home.searchKeywords,
    }),
  },
  data() {
    return {
      keywords: '',
      //keywordsList: ['废铁', '废钢', '废金属', '废钢板'],
      keywordsList:[],
    }
  },
  watch: {
    searchKeywords: {
      handler: function (newval, oldVal) {
        this.keywords = newval
      },
    },
  },
  mounted() {
    this.keywords = this.searchKeywords;
  },
  methods: {
    ...mapMutations('home', ['setSearchKeywords', 'setNavActive']),
    // 搜索
    handleSearch(keywords) {
      if (keywords) {
        this.setSearchKeywords(keywords)
      } else {
        this.setSearchKeywords(this.keywords)
      }
      this.$router.push('/home/hire')
      this.setNavActive(1)
      this.$bus.$emit('search')
    },
    // 返回首页
    toHome() {
      this.$router.push('/')
      this.setNavActive(0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.headerCon{
  width: 100%;
  position: relative;
}
::v-deep .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #A8A8A8;
}
::v-deep .el-carousel__indicator.is-active button {
  width: 20px;
  height: 8px;
  border-radius: 4px;
  background: #ffffff;
}
.component-container.header {
  height: 70px;
  position: absolute;
  top: 0;
  z-index: 1000;
  .component-content.header {
    height: 100%;
    width: $contentWidth;
    font-size: 12px;
    > .line {
      flex-shrink: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      &.line-1 {
        height: 100px;
        padding-top: 26px;
      }
      &.line-2 {
        justify-content: flex-start;
      }
    }
  }
}
.img-logo {
  cursor: pointer;
  width: 303px;
  // margin-top: 16px;
}
.search-box {
  .search-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .input {
      padding-left: 16px;
      width: 450px;
      height: 36px;
      line-height: 36px;
      border: 2px solid #e1251b;
      &::placeholder {
        color: #bbbbbb;
      }
    }
    .button {
      width: 103px;
      height: 36px;
      background: #e1251b;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .keywords {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .item {
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        color: $importantColor;
      }
    }
  }
}
.all-catrgory {
  margin-right: 60px;
}
</style>
