<template>
  <div class="component-container">
    <div class="component-content">
      <div class="item">
        <p class="label">{{ label }}</p>
        <div class="sub-list1" ref='subListREF' :class="{ open: open, check: check }" v-if="dataList">
          <div class="sub-item1" :class="{ active: active === -1 }" @click="handleChange(-1)">不限</div>
          <div class="sub-item1" ref='subItemREF' v-for="(item, index) in dataList" :key="item.key"
               :class="{ checked: checkedList[index].checked, active: active === index }" @click="handleChange(index, item)">
            {{ item.name || item.value }}
          </div>
        </div>
        <div class="operate">
          <div class="button" @click="open = !open" :class="{ active: open }" v-if="oprate.open">
            更多
            <i class="el-icon-arrow-down"></i>
          </div>
          <div class="button" @click="handleCheck" :class="{ active: check }" v-if="oprate.check">
            多选
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { apiGetSubArea, apiSearchList } from '@/api/index'

export default {
  name: 'SearchItem',
  components: {},
  props: {
    label: String,
    dataList: Array,
    oprate: {
      type: Object,
      default: () => {
        return {
          open: true,
          check: true,
        }
      },
    },
  },
  data() {
    return {
      activeItem: {},
      active: -1,
      checkedList: [],
      open: false,
      check: false,
      firstRowItemCount:0,
    }
  },
  computed: {
    ...mapState({}),
  },
  watch: {
    dataList: {
      handler(newVal, oldVal) {
        if (!newVal || (newVal && newVal.length === 0)) {
          return
        }
        this.checkedList = []
        newVal.forEach((item) => {
          this.checkedList.push({ checked: false })
        })

      },
      immediate: true,
    },
    active:{
      handler(newVal,oldVal){
        console.log(newVal,this.firstRowItemCount)
        this.open=newVal>this.firstRowItemCount
      }
    }
  },
  mounted() {
    this.checkItemsNotInFirstRow()
  },
  methods: {
    checkItemsNotInFirstRow() {
      const container = this.$refs.subListREF
      const items = this.$refs.subItemREF
      if (!items||items.length===0){
        return
      }
      const firstRowItems = Array.from(items).filter((item) => {
        const rect = item.getBoundingClientRect();
        return rect.top === container.getBoundingClientRect().top;
      });
      this.firstRowItemCount = firstRowItems.length-1;
    },
    // 多选
    handleCheck() {
      if (!this.check) {
        // 开启多选
        this.check = true
        this.active = -2
      } else {
        // 关闭多选
        this.check = false
        this.checkedList.forEach((item) => {
          item.check = false
          this.active = -1
        })
      }
      this.handleEmit()
    },
    // 点击
    handleChange(index, item) {
      if (index === -1) {
        this.active = -1
        this.check = false
      } else if (!this.check) {
        // 单选
        this.active = index
        this.activeItem = item
      } else {
        // 多选
        this.checkedList[index].checked = !this.checkedList[index].checked
        const checked = this.dataList.filter((item, index) => {
          return this.checkedList[index].checked
        })
      }
      this.handleEmit()
    },
    handleEmit() {
      if (!this.check) {
        if (this.active > -1) {
          // 单选
          if (this.oprate.check) {
            this.$emit('change', [this.activeItem])
          } else {
            this.$emit('change', this.activeItem)
          }
        } else {
          if (this.oprate.check) {
            this.$emit('change', [])
          } else {
            this.$emit('change', '')
          }
        }
      } else {
        // 多选
        const checked = this.dataList.filter((item, index) => {
          return this.checkedList[index].checked
        })
        this.$emit('change', checked)
      }
    },
    // 清除选中状态
    clearChecked() {
      this.active = -1
      this.checkedList.forEach((item) => {
        item.checked = false
      })
      this.handleEmit()
    },
    // 删除选中项
    deleteItem(item) {
      if (this.check === false) {
        this.active = -1
      } else {
        const index = this.dataList.findIndex((item2) => {
          if (item.key) {
            return item2.key === item2.key
          } else if (item.code) {
            return item2.code === item.code
          }
        })
        if (index > -1) {
          this.checkedList[index].checked = false
        }
      }
      this.handleEmit()
    },
    // 设置选中项
    setActive(index) {
      this.active = index
      this.activeItem = this.dataList[this.active]
      this.handleEmit()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
  color:$color1f;

  .label {
    margin-right: 10px;
    flex-shrink: 0;
    font-weight: bold;
  }
  .sub-list1 {
    flex-grow: 1;
    transition: 1s;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    overflow: hidden;
    &.open {
      height: auto;
    }
    &.check {
      .sub-item1 {
        padding-left: 20px;
        &::before {
          content: '';
          width: 14px;
          height: 14px;
          border: 1px solid #e7e7e7;
          margin-right: 6px;
        }
        &.checked {
          &::before {
            content: '';
            border: 1px solid $mainColor;
          }
          &::after {
            content: '';
            width: 11px;
            height: 9px;
            position: absolute;
            left: 23px;
            top: 13px;
            background: url('@/assets/images/home/icon-checked.png') no-repeat center / 100%;
          }
        }
      }
    }
    .sub-item1 {
      flex-shrink: 0;
      margin-right: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding-left: 20px;
      &:hover,
      &.active {
        color: $mainColor;
      }
    }
  }
  .operate {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      margin-left: 30px;
      cursor: pointer;
      &:hover,
      &.active {
        color: $mainColor;
      }
    }
  }
}
</style>
