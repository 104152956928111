<template>
  <div class="component-container" v-if="data">
    <div class="component-content" @click="handleClick">
      <div class="img-box">

        <img :src="data.firstPictureUrl" referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" class="img"/>
        <!-- <div class="badge">{{ badge.join('') }}</div> -->
      </div>
      <div class="title-box">
        <div class="title ellipsis" :title="noBadgeTitle">{{ noBadgeTitle }}</div>
      </div>
      <div class="desc-box" v-if="userInfo.userUuid">
        <template v-if="data.priceTextSet.length === 1">
          <div class="line" v-for="(item, index) in data.priceTextSet" :key="index">
            <div class="label secondary-text">{{ item.type  }}：</div>
            <div class="text important-text ellipsis"> <span class='bold'>{{ item.price }}</span> </div>
            <div class="text1 important-text ellipsis">  {{ item.unit}} </div>

          </div>
        </template>
        <template v-else>
          <div class="line" v-for="(item, index) in data.priceTextSet" :key="index">
            <div class="label secondary-text">{{ item.type }}：</div>
            <div class="text important-text ellipsis"><span class='bold'>{{item.price }}</span></div>
            <div class="text1 important-text ellipsis">{{ item.unit}}</div>

          </div>
        </template>
      </div>
      <div class="desc-box lock" v-else>
        <div class="line">
          <img src="@/assets/images/home/lock.png" class="img-lock" />
          <div class="text">登录后查看</div>
        </div>
      </div>
      <div class="bottom secondary-text">
        <i class="icon-location"></i>
        {{ data.provName.includes("市")?data.provName:data.provName+data.cityName }} 距离{{ data.geoDistance }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { checkVipLimit} from '@/api/detail'

export default {
  name: 'GoodsItem',
  components: {},
  props: {
    data: Object,
    subhead: String,
  },
  data() {
    return {
      subheads: this.subhead
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      infoNo: (state) => state.detail.infoNo,
    }),
    badge() {
      if (!this.data || !this.data.title) {
        return ''
      }
      const typeString = this.data.title.split('|')[0]
      const typeList = []
      for (let i = 0; i < typeString.length; i += 2) {
        if (typeString.slice(i, i + 2)) {
          typeList.push(typeString.slice(i, i + 2))
        }
      }
      return typeList
    },
    noBadgeTitle() {
      if (!this.data || !this.data.title) {
        return ''
      }
      return this.data.title

      // return this.data.title.split('|')[1]
    },
  },
  methods: {
    ...mapMutations('detail',['setGoodsInfo']),
    // 点击跳转详情
    handleClick() {
      // console.log(this.data)
      // console.log('点击了1')
      if (!this.userInfo.userUuid) {
        // 未登录状态，点击商品不跳转到详情页，跳转到登陆页；
        let detailinfo = {
          infoNo: this.data.infoNo,
          subheads: this.subheads || '',
          minheads: 'home',
          specId: this.data.specId,
          currentRouter:this.$route.path
        }
        this.setGoodsInfo(detailinfo)
        this.$router.push('/login')
      } else {
        // if(this.userInfo.vipFlag == 1){
          //已经是会员
          this.$router.push({
                path: '/product/detail',
                query: {
                  infoNo: this.data.infoNo,
                  subheads: this.subheads || '',
                  minheads: 'home',
                  specId: this.data.specId,
                },
              })
          // `/product/detail/${this.data.infoNo}/${this.subheads || ""}`)
          this.$emit('refresh') // 触发当前组件的 refresh 事件
        // }else{
        //   //不是会员，判断
        //   var params = {
        //     infoNo: this.data.infoNo
        //   }
        //   checkVipLimit(params).then((res) => {
        //     if (res.success) {
        //       // 登录成功后未开通会员，弹窗领取会员后可查看详情
        //       this.$router.push({
        //         path: '/product/detail',
        //         query: {
        //           infoNo: this.data.infoNo,
        //           subheads: this.subheads || '',
        //           minheads: 'home'
        //         },
        //       })
        //       // `/product/detail/${this.data.infoNo}/${this.subheads || ""}`)
        //       this.$emit('refresh') // 触发当前组件的 refresh 事件
        //     } else if(res.code == 'A1006') {
        //       // this.$confirm('领取会员后才能查看详情, 是否去领取会员?', '提示', {
        //       //   confirmButtonText: '确定',
        //       //   cancelButtonText: '取消',
        //       //   type: 'warning'
        //       // }).then(() => {
        //       //   console.log('点击了1.0')
        //       //   this.$emit('set-vip-show','1');
        //       // }).catch(() => {
        //       //   // this.$message({
        //       //   //   type: 'info',
        //       //   //   message: '已取消删除'
        //       //   // });
        //       // });
        //       this.$emit('set-vip-show','1');
        //     }else{
        //       this.$message({type:'info',message:res.msg});
        //     }
        //   });
        // }
        
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.component-container {
  width: 248px;
  height: 385px;
  background: $colorF9;
  border-radius: 16px;
  cursor: pointer;
}
.img-box {
  width: 100%;
  height: 248px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: $colorF9;
  border-radius: 16px;
  .img {
    // max-width: 100%;
    // max-height: 100%;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .badge {
    position: absolute;
    left: 8px;
    top: 8px;
    padding: 3px 10px 5px 5px;
    height: 24px;
    // background: url('@/assets/images/home/badge.png') no-repeat right center / auto 100%;
    font-size: 12px;
    color: #ffffff;
  }
}
.title-box {
  margin: 10px 0;
  padding: 0 15px;
  width: 100%;
  height: 21px;
  line-height: 21px;
  font-weight: bold;
  color: $color1f;
}
.desc-box {
  width: 100%;
  height: 55px;
  padding: 0 15px;
  .line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    .label {
      flex-shrink: 0;
      color: $color1f;
    }
    .text {
      flex-shrink: 0;
      max-width: 115px;
      text-overflow: ellipsis;
      font-weight: bold;
    }
    .text1 {
      flex-shrink: 0;
      max-width: 80px;
      text-overflow: ellipsis;
  

    }
   
  }
  &.lock {
    .line {
      width: 100%;
      justify-content: center;
      color: #D6D6D6;
      .img-lock {
        width: 27px;
        margin-right: 10px;
      }
      .text {
        width: auto;
      }
    }
  }
}
.bottom {
  margin-top: 10px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  color: $colorA6;
}

.icon-location {
  width: 11px;
  height: 14px;
  background: url('@/assets/images/public/icon-position-gray.png') no-repeat center / 100%;
  margin-right: 6px;
}
</style>
