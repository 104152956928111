<template>
    <div class="tab-menu">
      <div class="tab-menu-item" v-for="(item,index) in menuList" :key="item.name" @mouseover="onMouseOver(item)" @mouseout="onMouseOut(item)"  @click="handleNavClick(index,item.navVlaue)">
        <img :src="item.currentImgUrl" alt="">
        <span class="name">{{ item.name }}</span>
        <span class="tips">{{ item.tips }}</span>
      </div>
    </div>
  </template>
<script>
    import { mapMutations } from 'vuex'
    import { JupaiUrl } from '@/constant/index'
    export default {
        name: 'TabMenu',
        data(){
            return{
                menuList: [
                    {
                        name: '物资出租',
                        tips: '高效安全的租赁运营',
                        imgUrl: require('@/assets/images/home/tab01-icon.png'),
                        currentImgUrl: require('@/assets/images/home/tab01-icon.png'),
                        hoverImgUrl: require('@/assets/images/home/tab01-icon-active.png'),
                        navVlaue:'hire'
                    },
                    {
                        name: '物资出售',
                        tips: '便捷合规的物资交易',
                        imgUrl: require('@/assets/images/home/tab02-icon.png'),
                        currentImgUrl: require('@/assets/images/home/tab02-icon.png'),
                        hoverImgUrl: require('@/assets/images/home/tab02-icon-active.png'),
                        navVlaue:'sell'
                    },
                    {
                        name: '物资求租',
                        tips: '快速准确的获得所需',
                        imgUrl: require('@/assets/images/home/tab03-icon.png'),
                        currentImgUrl: require('@/assets/images/home/tab03-icon.png'),
                        hoverImgUrl: require('@/assets/images/home/tab03-icon-active.png'),
                        navVlaue:'seekingrent'
                    },
                    {
                        name: '物资求购',
                        tips: '保质低价的商品采购',
                        imgUrl: require('@/assets/images/home/tab04-icon.png'),
                        currentImgUrl: require('@/assets/images/home/tab04-icon.png'),
                        hoverImgUrl: require('@/assets/images/home/tab04-icon-active.png'),
                        navVlaue:'buy'
                    },
                  {
                    name: '物资置换',
                    tips: '物资价值的最优方案',
                    imgUrl: require('@/assets/images/home/tab05-icon.png'),
                    currentImgUrl: require('@/assets/images/home/tab05-icon.png'),
                    hoverImgUrl: require('@/assets/images/home/tab05-icon-active.png'),
                    navVlaue:'exchange'
                  },
                    {
                        name: '物资竞拍',
                        tips: '高效处置废旧物资',
                        imgUrl: require('@/assets/images/home/tab06-icon.png'),
                        currentImgUrl: require('@/assets/images/home/tab06-icon.png'),
                        hoverImgUrl: require('@/assets/images/home/tab06-icon-active.png'),
                        navVlaue:'auction'
                    }
                ]
            }
        },
        methods: {

            ...mapMutations('home', ['setNavActive']),
            onMouseOver(item) {
                item.currentImgUrl = item.hoverImgUrl;
            },
            onMouseOut(item) {
                item.currentImgUrl = item.imgUrl;
            },
            handleNavClick(index,navVlaue) {
                if (index === 5) {
                    window.open(JupaiUrl)
                    return
                }
                this.$router.push('/home/' + navVlaue)
                this.setNavActive(index+1)
             },
           }
    }
</script>
<style scoped lang="scss">
    @import '@/styles/variables';
    .tab-menu{
      display: flex;
      width: 100%;
      height: 130px;
      background: $colorWhite;
      border-radius: 16px;
      justify-content: space-evenly;
      padding: 10px 0;
      .tab-menu-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 205px;
        height: 110px;
        background: $colorFB;
        border-radius: 16px;
        justify-content: center;
        img{
            width: 38px;
            height: 38px;
            margin-bottom: 7px;
        }
        span{
            color: $color1f;
            line-height: 23px;
        }
        .name{
            font-size: 16px;
            font-weight: bold;
          font-style: italic;
        }
        .tips{
            font-size: 12px;
            color: $colorA6;
        }
        &:hover{
            background: $mainColor;
            span{
                color: $colorWhite;
                line-height: 23px;
            }
        }
      }
    }
</style>