<template>
  <div class='honor'>
    <div class='goods-item' v-for='item in bannerList' :key='item.id'>
      <div class='img-box'>
        <img :src='item.imgSrc' class='img' />
      </div>
      <div class='title-box'>
        <div class='title ellipsis2'>{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetBannerList } from '@/api'

export default {
  name: 'IntroductionHonor',
  data() {
    return {
      bannerList: []
    }
  },
  mounted() {
    apiGetBannerList({ spaceId: '27' }).then((res) => {
      this.bannerList = res.data.data
    })
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/variables';

.honor {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
  flex-wrap: wrap;
margin-bottom: 12px;
  min-height: 600px;
  .goods-item {
    width: 415px;
    height: 300px;
    margin-right: 8px;
    margin-bottom: 8px;
    background: linear-gradient(0deg, #F3F3F3 0%, #D8D8D8 100%);
    padding: 16px;
    text-align: center;

    &:nth-child(4n) {
      margin-right: 0;
    }

    .img-box {
      width: 380px;
      height: 246px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

    }
  }

  .title-box {
    margin: 10px 0;
    padding: 0 15px;
    width: 100%;
    height: 21px;
    line-height: 21px;
    font-weight: bold;
    color: $color1f;
  }
}
</style>