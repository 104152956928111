<template>
  <div class='component-container'>
    <div class='component-content'>
      <el-dialog :visible.sync='showDialog' :show-close='false' :lock-scroll="false" width='600px' :close-on-click-modal='false'
                 title='咨询留言'>
        <el-form :model='form' style='background: #FFFFFF'>
          <el-row>
            <el-col :span='12'>
              <el-form-item label='规格' style='display: flex'>
                <div slot='label' style='width: 60px'>{{ '规格' }}</div>
                <el-input v-model='form.specName' disabled maxlength='15'></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='联系人' style='display: flex'>
                <div slot='label' style='width: 60px'>{{ '联系人' }}</div>
                <el-input v-model='form.intentContactName' placeholder='请输入联系人' maxlength='15'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='12'>
              <el-form-item label='联系电话' style='display: flex'>
                <div slot='label' style='width: 60px'>{{ '联系电话' }}</div>
                <el-input v-model='form.intentContactPhone' placeholder='请输入电话' maxlength='11'></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='公司名称' style='display: flex'>
                <div slot='label' style='width: 60px'>{{ '公司名称' }}</div>
                <el-input v-model='form.intentCompany' placeholder='请输入公司名称' maxlength='30'></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item style='display: flex'>
            <div slot='label' style='width: 60px'>{{ '地址' }}</div>
            <div style='display: flex'>
              <el-cascader
                v-model='propsValue'
                :props='props'
                @change='handleChange'></el-cascader>
              <el-input v-model='form.intentAddress' placeholder='请输入详细地址' style='margin-left: 20px;width: 246px'
                        maxlength='20'></el-input>
            </div>

          </el-form-item>
          <el-form-item style='display: flex'>
            <div slot='label' style='width: 60px'>{{ '咨询内容' }}</div>
            <el-input v-model='form.intentContent' style='width: 488px' type='textarea' :rows='2'
                      placeholder='请输入咨询内容'></el-input>
          </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button @click="showDialog = false , $emit('set-show-consultation', false)" round size='medium'
                     style='width: 110px'>取 消</el-button>
          <el-button type='primary' @click='leaveMessage()' round size='medium' style='width: 110px'>确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiGetSubArea } from '@/api'
import { consultLeaveMsg } from '@/api/intentOrder'

export default {
  name: 'ConsultationMessage',
  props: {
    isShowDialog: {
      type: [Boolean],
      default: false
    },
    infoNo: {
      type: [String],
      default: ''
    },
    specId: {
      type: [String,Number],
      default: ''
    },
    specName: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      showDialog: this.isShowDialog,
      form: {
        intentContactName: '',
        intentContactPhone: '',
        intentCompany: '',
        intentContent: '',
        intentCityId: '',
        intentProvinceId: '',
        intentAreaId: '',
        intentAddress: '',
        infoNo: '',
        specId: '',
        specName: ''
      },
      formLabelWidth: '120px',
      propsValue: '',
      props: {
        expandTrigger: 'hover',
        lazy: true,
        lazyLoad(node, resolve) {
          let data = {}
          if (node.level == 0) {
            data = { 'parentCode': '' }
          } else {
            data = { 'parentCode': node.value }
          }
          const { level } = node
          apiGetSubArea(data).then((res) => {
            if (res.code == '000000') {
              const nodes = res.data.map(item => ({
                  value: item.code,
                  label: item.name,
                  leaf: level >= 2
                })
              )
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
              resolve(nodes)
            } else {
              // MessageBox(res.msg)
              console.log(res.msg)
              resolve([])
            }
          })
        }
      }
    }
  },
  watch: {
    isShowDialog: {
      handler(newVal, oldVal) {
        if (newVal) {
          console.log(newVal)
          this.showDialog = true
        } else {
          console.log(newVal + '1111')
          this.showDialog = false
        }
      }
    },
    infoNo: {
      handler(newVal, oldVal) {
        this.form.infoNo = newVal
      }
    },
    specId: {
      handler(newVal, oldVal) {
        this.form.specId = newVal
      }
    },
    specName: {
      handler(newVal, oldVal) {
        this.form.specName = newVal
      }
    }
  },
  mounted() {
    this.form.specId = this.specId
    this.form.specName = this.specName
    this.form.infoNo = this.infoNo

  },
  methods: {
    handleChange(value) {
      this.form.intentProvinceId = value[0]
      this.form.intentCityId = value[1]
      this.form.intentAreaId = value[2]
    },
    leaveMessage() {
      console.log(this.form)
      if (!this.form.intentContactName){
        this.$message.error("请输入联系人")
        return
      }
      if (!this.form.intentContactPhone){
        this.$message.error("请输入联系电话")
        return
      }
      if (!this.form.intentCompany){
        this.$message.error("请输入公司名称")
        return
      }
      if (!this.form.intentAreaId){
        this.$message.error("请选择省市区")
        return
      }
      if (!this.form.intentAddress){
        this.$message.error("请输入详细地址")
        return
      }
      if (!this.form.intentContent){
        this.$message.error("请输入咨询内容")
        return
      }
      this.showDialog = false
      consultLeaveMsg(this.form).then((res) => {
        const { code, msg, data } = res
        if (code === '000000') {
          this.$message({
            message: data,
            type: 'success',
            duration: 2000,
            onClose: () => {
              // this.showDialog = false
              this.$emit('set-show-consultation', false)
            }
          })
          this.form.intentContactName = ''
          this.form.intentContactPhone = ''
          this.form.intentCompany = ''
          this.form.intentContent = ''
          this.form.intentCityId = ''
          this.form.intentProvinceId = ''
          this.form.intentAreaId = ''
          this.form.intentAddress = ''
          this.propsValue=''
        } else {
          this.$message({
            message: msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-dialog__header {
  background: linear-gradient(90deg, #BEDEFF 0%, #CAE1FF 38%, #DBE8FF 70%, #E2EAFF 100%);
  border-radius: 16px 16px 0px 0px;
  height: 70px;
}

::v-deep .el-dialog {
  border-radius: 16px;
}

::v-deep .dialog-footer {
  justify-content: space-evenly;
  display: flex;
}
</style>