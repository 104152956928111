var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-container1",class:{ borderRadiusClass: _vm.borderRadiusClass }},[_c('div',{staticClass:"component-content"},[_c('div',{staticClass:"list"},_vm._l((_vm.allCategoryList),function(item,index1){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.value!='其他'),expression:"item.value!='其他'"}],key:item.key,staticClass:"item",on:{"mouseenter":function($event){return _vm.handleCategoryMouseEnter(index1)},"mouseleave":function($event){return _vm.handleCategoryMouseLeave(index1)}}},[_c('div',{staticClass:"itemInfo"},[_c('div',{staticClass:"iconImg"},[(index1 === 0)?_c('img',{staticClass:"classifyIcon",attrs:{"src":require("@/assets/images/home/classify01.png")}}):_vm._e(),(index1 === 1)?_c('img',{staticClass:"classifyIcon",attrs:{"src":require("@/assets/images/home/classify02.png")}}):_vm._e(),(index1 === 2)?_c('img',{staticClass:"classifyIcon",attrs:{"src":require("@/assets/images/home/classify03.png")}}):_vm._e(),(index1 === 3)?_c('img',{staticClass:"classifyIcon",attrs:{"src":require("@/assets/images/home/classify04.png")}}):_vm._e(),(index1 === 4)?_c('img',{staticClass:"classifyIcon",attrs:{"src":require("@/assets/images/home/classify05.png")}}):_vm._e(),(index1 === 5)?_c('img',{staticClass:"classifyIcon",attrs:{"src":require("@/assets/images/home/classify06.png")}}):_vm._e(),(index1 === 6)?_c('img',{staticClass:"classifyIcon",attrs:{"src":require("@/assets/images/home/classify07.png")}}):_vm._e()]),_c('div',{staticClass:"clssifyCon"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.value))]),_c('i',{staticClass:"el-icon-arrow-right"})]),(item.children)?_c('div',{staticClass:"sub-list"},[(
                  item.children
                    .slice(0, 3)
                    .map((item) => {
                      return item.name
                    })
                    .join('/').length > 17
                )?_vm._l((item.children.slice(0, 2)),function(subitem,index2){return _c('div',{key:subitem.id,staticClass:"secondary-text",on:{"click":function($event){return _vm.handleClick(index1, index2, -1)}}},[(index2 > 0)?_c('span',[_vm._v("/")]):_vm._e(),_c('span',{staticClass:"sub-item"},[_vm._v(_vm._s(subitem.name))])])}):_vm._l((item.children.slice(0, 3)),function(subitem,index2){return _c('div',{key:subitem.id,staticClass:"secondary-text",on:{"click":function($event){return _vm.handleClick(index1, index2, -1)}}},[(index2 > 0)?_c('span',[_vm._v("/")]):_vm._e(),_c('span',{staticClass:"sub-item"},[_vm._v(_vm._s(subitem.name))])])})],2):_vm._e()])]),(_vm.popupList[index1].show && item.children.length > 0)?_c('div',{staticClass:"popup-box"},_vm._l((item.children),function(more,index2){return _c('div',{key:more.name + index2,staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(more.name))]),_c('div',{staticClass:"sub-list"},_vm._l((more.sonList),function(subitem,index3){return _c('div',{key:subitem.name + index3,staticClass:"secondary-text",on:{"click":function($event){return _vm.handleClick(index1, index2, index3)}}},[_c('span',{staticClass:"sub-item"},[_vm._v(_vm._s(subitem.name))])])}),0)])}),0):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }