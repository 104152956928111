<template>
    <div class="info-right-box">
        <img  class="top_Banner" src="@/assets/images/realname/accountTopBanner.png"  alt="">

        <!-- 标题及上传营业执照模块 -->
        <div class="title-box">
            <div class="title-box-name ">企业信息</div>
            <div class="title-box-subName" style="color: #E1251B;">营业执照/身份证照片仅用于平台实名认证，每日循环平台将保证您的信息安全，并承诺不会用于其他目的。</div>
        </div>
        <!-- 上传营业执照图片 -->
        <div class="business-box">
            <div style="color: #323C32; height: 20px; font-size: 14px; line-height: 20px; font-weight: 500;">
                请上传营业执照照片</div>
            <div style="color: #BEBEBE; height: 17px; font-size: 12px; line-height: 17px; font-weight: 400;">
                拍摄时确保营业执照边框完整，字体清晰，亮度均匀大小控制在2MB以内</div>
            <div class="business-img-box" style="display: flex;">
                <upload-image :srclen='0' :upimg="form.serLicenseImg" @success="onSuccess">
                </upload-image>
                <div class="img-box" style="margin-left:20px ;">
                    <img class="cardImg" src="@/assets/images/realname/realname_1.png" alt="">
                </div>
            </div>
        </div>
        <!-- 身份信息模块 -->
        <div class="cardInfo-box" style="margin-top:30px; width: 563px; ">
            <div class="cardInfo-box-title" style="">认证身份</div>
            <div class="cardInfo-box-select" style="height: 20px; margin-top: 20px; display: flex; align-items: center;">
                <el-radio v-model="form.serAuthType" style="margin-right: 10px; font-size: 14px;" label='0'>企业法人</el-radio>
                <el-radio v-model="form.serAuthType" style="margin-right: 0px;font-size: 14px;" label='1'>被委托人</el-radio>
                <p style="font-size: 14px; height: 20px; line-height: 20px;">（若没有法人身份信息，可以出具委托书后代为认证<span
                        style="color: red;  cursor: pointer;" @click="openTemplate">下载模版 </span>）</p>
            </div>
            <!-- 身份证正面 -->
            <div class="cardInfo-box-img">
                <upload-image :srclen='1' :upimg="form.serAuthType == '0' ? form.serLegalFrontImg : form.serEntrustFrontImg"
                    @success="onSuccess">
                </upload-image>
                <div class="img-box" style="margin-left:20px ;">
                    <img class="cardImg" src="@/assets/images/realname/realname_2.png" alt="">
                </div>
            </div>
            <!-- 身份证反面 -->

            <div class="cardInfo-box-img">
                <upload-image :srclen='2' :upimg="form.serAuthType == '0' ? form.serLegalBackImg : form.serEntrustBackImg"
                    @success="onSuccess">
                </upload-image>
                <div class="img-box" style="margin-left:20px ;">
                    <img class="cardImg" src="@/assets/images/realname/realname_3.png" alt="">
                </div>
            </div>
            <!-- 手持 -->
            <div class="cardInfo-box-img">
                <upload-image :srclen='3'   :upimg="form.serAuthType == '0' ? form.serLegalHandImg : form.serEntrustHandImg" @success="onSuccess">
                </upload-image>
                <div class="img-box" style="margin-left:20px ;">
                    <img class="cardImg" src="@/assets/images/realname/realname_4.png" alt="">
                </div>
            </div>
            <!-- 委托书 -->
            <div class="cardInfo-box-img" v-show="form.serAuthType == '0' ? false : true">
                <upload-image :srclen='4' :upimg="form.serEntrustImg" @success="onSuccess">
                </upload-image>
                <div class="img-box" style="margin-left:20px ;">
                    <img class="cardImg" src="@/assets/images/realname/realname_5.png" alt="">
                </div>
            </div>
        </div>

        <div class="el-item-submit" @click="baseInfo">信息确认</div>


    </div>
</template>
      
<script>

import UploadImage from './componets/UploadImage.vue'
import { ocrIdCard } from '@/api/index'
import { orcCompany } from '@/api/index'
// import { apiEditUserAuth } from '@/api/index'
import bus from '@/views/accountManage/bus.js'

export default {
    name: 'CompanyIndex',
    props: ['options'],
    computed: {

    },
    components: {
        UploadImage
    },
    mounted() {
        bus.$on("changeForm", value => {    
            this.form = value
        });

    },

    data() {
        return {
            form: this.options,
            
            openurl: 'https://file.jupai.net/jupai/jlease/shouquanweituoshu.doc',
        }
    },
    methods: {
        baseInfo() {
            if (!this.form.serLicenseImg) {
                this.$message({ message: "请上传营业执照图片", type: "warning" });
                return
            }
            //法人
            if (this.form.serAuthType === '0') {
                if (!this.form.serLegalFrontImg) {
                    this.$message({ message: "请上传身份证正面图片", type: "warning" });
                    return
                } else if (!this.form.serLegalBackImg) {
                    this.$message({ message: "请上传身份证反面图片", type: "warning" });
                    return
                } else if (!this.form.serLegalHandImg) {
                    this.$message({ message: "请上传手持图片", type: "warning" });
                    return
                }
            } else {
                if (!this.form.serEntrustFrontImg) {
                    this.$message({ message: "请上传身份证正面图片", type: "warning" });
                    return
                } else if (!this.form.serEntrustBackImg) {
                    this.$message({ message: "请上传身份证反面图片", type: "warning" });
                    return
                } else if (!this.form.serEntrustHandImg) {
                    this.$message({ message: "请上传手持图片", type: "warning" });
                    return
                } else if(!this.form.serEntrustImg) {
                    this.$message({ message: "请上传委托书图片", type: "warning" });
                    return
                }
            }
            this.$emit('submitForm', this.form)
        },

        openTemplate() { //下载模版
            if (this.openurl != '' && this.openurl != null) {
                window.open(this.openurl, '_blank');
            }
        },
        // 上传图片赋值
        onSuccess(item, index) {
            if (index == 0) {//营业执照
                this.form.serLicenseImg = item
            } else if (index == 1) {//身份证正面
                if (this.form.serAuthType === '0') {
                    this.form.serLegalFrontImg = item
                } else {
                    this.form.serEntrustFrontImg = item
                }

            } else if (index == 2) {//身份证反面
                if (this.form.serAuthType === '0') {
                    this.form.serLegalBackImg = item
                } else {
                    this.form.serEntrustBackImg = item
                }
            } else if (index == 3) {//手持
                if(this.form.serAuthType == '0' ) {
                    this.form.serLegalHandImg = item
                } else {
                    this.form.serEntrustHandImg = item
                }
                // 
            } else if (index == 4) {//委托书
                this.form.serEntrustImg = item
            }

            //身份证ocr
            const params = { 'imgUrl': item }
            //身份证 正反面进行ocr识别
            if (index == 1 || index == 2) {
                ocrIdCard(params).then((res) => {
                    if (res.code == '000000') {
                        const ocrJson = res.data
                        //身份证正面
                        if (index == 1) {
                            //法人
                            if (this.form.serAuthType == '0') {
                                this.form.serLegalName = ocrJson['name']
                                this.form.serLegalNo = ocrJson['idNumber']
                                this.form.serEntrustName = ''
                                this.form.serEntrustNo = ''

                            } else {
                                this.form.serEntrustName = ocrJson['name']
                                this.form.serEntrustNo = ocrJson['idNumber']
                                this.form.serLegalName = ''
                                this.form.serLegalNo = ''
                            }

                            //身份证反面-身份证有效期 可能需要截取 -（横杠） 之后的数据
                        } else if (index == 2) {
                            const endTime = ocrJson['validPeriod']
                            let isContain = endTime.includes('-')
                            let isCQ = endTime.includes('长期')
                            if(isCQ) {
                                this.form.serIdCardTime = ''
                                this.form.serIdCardType = '1'
                            } else {
                                if (isContain) {
                                const index = endTime.indexOf("-") + 1
                                const serIdCardTime = endTime.substring(index)
                                const serIdCardTimeArr = serIdCardTime.split('.')
                                this.form.serIdCardTime = serIdCardTimeArr.join("-")

                            } else {
                                this.form.serIdCardTime = endTime
                            }
                            this.form.serIdCardType = '0'

                            }


                        }

                    } else {
                        console.log('实名认证错误')
                    }
                })
                //营业执照ocr
            } else if (index == 0) {
                orcCompany(params).then((res) => {
                    if (res.code == '000000') {
                        const ocrJson = res.data

                        this.form.serCompanyName = ocrJson['companyName']
                        this.form.serLicenseCode = ocrJson['licenseCode']

                        const serBusinessBeginDate = ocrJson['businessBeginDate']
                        const serBusinessEndDate = ocrJson['businessEndDate']

                        if(serBusinessBeginDate.includes('长期') || serBusinessEndDate.includes('长期')) {
                            this.form.serBusinessBeginDate = ''
                            this.form.serBusinessEndDate = ''
                            //是否长期有效
                            this.form.serBusinessDateAll = '1'
                            return
                        } 
                        //当ocr 没有识别出来信息的时候 赋值为空
                        if(serBusinessBeginDate.includes('无')) {
                            this.form.serBusinessBeginDate = ''
                            console.log('营业执照ocr开始',this.form.serBusinessBeginDate)

                        } else {
                            const serBusinessBeginDate1 = serBusinessBeginDate.replace('年', '-')
                                 const serBusinessBeginDate2 = serBusinessBeginDate1.replace('月', '-')
                                const serBusinessBeginDate3 = serBusinessBeginDate2.replace('日', '')
                                this.form.serBusinessBeginDate = serBusinessBeginDate3
                        }
                        
                        if(serBusinessEndDate.includes('无')) {
                            this.form.serBusinessEndDate = ''
                            console.log('营业执照ocr信结束',this.form.serBusinessEndDate)

                        } else {
                       
                        const serBusinessEndDate1 = serBusinessEndDate.replace('年', '-')
                        const serBusinessEndDate2 = serBusinessEndDate1.replace('月', '-')
                        const serBusinessEndDate3 = serBusinessEndDate2.replace('日', '')
                        this.form.serBusinessEndDate = serBusinessEndDate3
                        }

                        //是否长期 
                        this.form.serBusinessDateAll = '0'


                    } else {
                        console.log('营业执照ocr信息错误',res)

                    }
                })

            }

        },

    },

}
</script>
      
<style lang="scss" scoped>
@import '@/styles/variables';

.info-right-box {
    flex: 1;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.top_Banner {
width: 1060px;
height: 154px;
position: absolute;
}
// 标题模块box
.title-box {
    // margin-top: 0px;
    width: 100%;
    height: 154px;
    // background: url(@/assets/images/realname/accountTopBanner.png);

    .title-box-name {
        margin-left: 247px;
        margin-top: 80px;
        height: 31px;
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        line-height: 31px;
    }

    .title-box-subName {
        margin-left: 247px;
        margin-top: 5px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 17px;
        // color: #E1251B;
    }
}

//营业执照上传图片box
.business-box {
    width: 563px;
    height: 183px;
    margin-top: 15px;

    .business-img-box {
        height: 120px;
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }
}

.img-box {
    width: 166px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px #ECECEC dashed
}

.cardImg {
    width: 126px;
    height: 80px;

}

//认证身份box
.cardInfo-box {
    .cardInfo-box-title {
        height: 31px;
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #323C32;
        line-height: 31px;
    }

    .cardInfo-box-img {
        display: flex;
        margin-top: 15px;
        height: 120px;
        justify-content: center;

    }

}

.cardInfo-box-select ::v-deep .el-radio__inner {
    width: 12px;
    height: 12px;
}

.el-item-submit {
    background: #E1251B;
    width: 168px;
    height: 42px;
    margin-top: 19px;
    color: #FFFFFF;
    border-radius: 4px;
line-height: 42px;
text-align: center;
}

</style>
