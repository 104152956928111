<template>
  <div class="right-box">
    <el-form label-width="100px" class="el-form-content">
      <el-form-item label="注册手机号" prop="name">
        <el-input placeholder="请输入您的手机号" class="el-item-text"  :readonly="readOnlyBOOL" v-model="mobile">
          <el-button slot="suffix" :disabled="countdown > 0" @click="startCountdown" type="text" class="el-item-e1251b el-item-button">{{ countdown > 0 ? countdown + '秒' : '获取验证码'}}</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="短信验证码" prop="name">
        <el-input placeholder="请输入短信验证码" class="el-item-text" v-model="smsCode">
        </el-input>
      </el-form-item>

    </el-form>
    <div class="tip">收不到短息验证码？<br>请检查手机网络并且核实手机是否屏蔽系统短信，如均正常请重新获取或稍后再试。</div>
    <div style='height: 3vh'></div>
    <el-button class="el-item-submit" @click="submitClick">提交认证</el-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { sendSMS } from '@/api/common'
import {checkPhoneCode} from '@/api/user'

export default {
  name: 'changePassword',
  components: {},
  computed: {
    ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
  },
  data() {
    return {
      mobile: '',
      smsCode: '',
      countdown: 0,
      timer: null,
      readOnlyBOOL:''

    }
  },
  created() {
    this.mobile = this.userInfo.phoneHide
    //未登录
    if (!this.userInfo.userUuid) {
      this.readOnlyBOOL = false
    } else {
      this.readOnlyBOOL = true
    }
  },
  methods: {
    submitClick() {
      var phone = ''
      //未登录状态
      if (!this.userInfo.userUuid) {
        if (!this.mobile || this.mobile == "") {
          this.$message({ message: "请输入手机号", type: "warning" });
          return;
        }
        phone = this.mobile
      } else {
        phone = this.userInfo.phone
      }
   
      if(!this.smsCode || this.smsCode == ""){
        this.$message({message: "请输入短信验证码！",type: "warning"});
        return;
      }
      // 1.请求接口
      var data = {
        "phone": phone,
        "code": this.smsCode
      }
      checkPhoneCode(data).then(res=>{
        console.log(res)
        if(res.code == '000000'){
           //切换到第二步
          this.$emit('step1Next',data)
        }else{
          this.$message({message: res.msg,type: "warning"});
        }
      })
    },
    startCountdown() {
      // if(!this.mobile || this.mobile == ""){
      //   this.$message({message: "请输入手机号",type: "warning"});
      //   return;
      // }
        var phone = ''
      //未登录状态
      if (!this.userInfo.userUuid) {
        if (!this.mobile || this.mobile == "") {
          this.$message({ message: "请输入手机号", type: "warning" });
          return;
        }
        phone = this.mobile
      } else {
        phone = this.userInfo.phone
      }
      var data = {
        "telephone": phone
      }
      sendSMS(data).then(res=>{
        console.log(res);
        if(res.code == '000000'){
          //获取验证码成功
           this.countdown = 59;
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
              } else {
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
        }else{
          this.$message({message: res.msg,type: "warning"});
        }
      })
     
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  watch: {
       countdown: {
           handler(newVal) {
               this.countdown = newVal
           }
       }
   }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.right-box {
  width: 100%;
  height: 721px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;


  .tip {
    margin-left: 248px;
    color: #BBBBBB;
    font-size: 12px;
    font-family: MicrosoftYaHei;

  }

  ::v-deep .el-input--small .el-input__inner {
    width: 295px;
    // height: 36px;
  }

  .el-item-submit {
    background: #E1251B;
    width: 168px;
    height: 42px;
    color: #FFFFFF;
    border-radius: 4px;

  }

}
</style>
    