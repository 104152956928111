<template>
<div class='brief'>
  <img src="@/assets/images/public/icon-introduction-brief-top.png" style="max-width: 98%" />
  <img src='@/assets/images/public/icon-introduction-brief-centre.png' style='max-width: 530px'/>
  <div class='brief-title'>每日循环隶属于中废通集团，总公司成立于2004年</div>
  <div class='brief-content'>以打通建筑行业全产业链为愿景，致力于实现各业务节点的紧密对接，推动产业资源的循环共享，专注实现全链条经济组织在企业链、供需链、价值链和空间链四大维度高效协同和价值创新，以技术赋能产业进步，以创新推动绿色发展。全面布局建筑物资闲置资产循环运营与数字循环价值共享两大战略方向，通过物资标准化和集约化建设、逆向供应链建设、数字循环平台建设与产业绿色生态建设等四大创新性解决方案，聚焦开发并释放建筑物资产业超量闲置资产全寿命周期可持续绿色循环利用的巨大价值。深入调研并深刻理解建筑物资产业闲置物资循环利用的当前现状和运营痛点，立足建筑物资产业进行物资循环运营模式创新，建设建筑物资产业互联网平台，提供建筑物资循环运营与建筑物资数字循环两大服务。平台资源的聚合与重构，输出“全链路”服务方案，协助客户进行物资盘点、分类，安全存储转运，将物资本身之外所产生的费用“化零为整”；再通过平台资源整合能力将闲置物资再利用，通过多种最优运营方案，减少物资浪费。依托自身资源优势，通过跨区域、跨品类“大循环、大置换”的创新商业模式，实现新旧物资高效流转，既减少客户的费用支出，又能协助企业实现闲置物资价值最大化。</div>
</div>
</template>

<script>
export default {
  name: 'IntroductionBrief'
}
</script>

<style lang='scss' scoped>
.brief{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
  .brief-title{
    font-weight: bold;
    font-size: 24px;
    color: #1F1F1F;
    line-height: 31px;
    text-align: justify;
    font-style: normal;
  }
  .brief-content{
    font-size: 14px;
    color: #999999;
    line-height: 27px;
    text-align: justify;
    font-style: normal;
    margin-bottom: 40px;
    margin-top: 20px;
    margin-right: 20px;
  }
}
</style>