<template>
  <div class='item-module-goods'>
    <div v-show="consultationList.length > 0">
      <div v-for="(item, index) in consultationList" :key="index + 'l'">
        <div class="item-module-goods-list">
          <div class="item-module-goods-list-top">
            <p class="item-mess-status-icon" style="margin-left: 0px">
              <span v-if="item.infoStatus == 0">未上架</span>
              <span v-if="item.infoStatus == 1">待上架</span>
              <span v-if="item.infoStatus == 2">已上架</span>
              <span v-if="item.infoStatus == 3">已下架</span>
              <span v-if="item.infoStatus == 4">已下架</span>
              <span v-if="item.infoStatus == 5">未上架</span>
            </p>
            <p class="item-module-goods-list-top-text">发布时间：{{ item.addTime }}</p>
            <p class="item-module-goods-list-top-text">物资编号：{{ item.infoNo }}</p>
          </div>
          <div class="item-module-goods-list-bottom">
            <div class="item-module-goods-list-bottom-left" @click="handleDetail(item.infoNo,item.specId)">
              <img referrer="no-referrer|origin|unsafe-url" referrerPolicy="no-referrer" :src="item.firstPictureUrl" class="item-module-goods-list-bl-img" />
              <div class="item-module-goods-list-bl-content">
                <p class="item-module-goods-list-bl-content-p" style="font-weight: bold">{{ item.materialName }}</p>

                <div class='item-module-goods-list-bottom-right'>
                  <div class='goods-list-item-info'>
                    <p class="item-module-goods-list-bl-content-p ellipsis" style="width: 250px;text-align: left;">规格型号：{{ item.specName }}</p>
                    <p class="item-module-goods-list-bl-content-p" style="display: flex;margin: 3px 0 10px 10px;">物资数量：{{ item.qty }}{{ item.qtyUnit }}</p>
                    <div class="item-module-goods-list-bl-content-p-rent" style="margin-bottom: 5px" v-for="(item1, index1) in item.priceTextSet" :key="index1 + 'l'">
                      <div class="item-module-goods-list-bl-content-p-rent2">
                        <p style="color: #bbbbbb" v-show="item1.type">{{ item1.type }}：</p>
                        <div class="text important-text ellipsis"><span>{{ item1.price }}</span></div>
                        <p style="color: #e1251b; font-weight: normal;">{{ item1.unit }}</p>
                      </div>
                    </div>
                  </div>

                  <div class='goods-list-item-info'>
                    <div  class="item-module-goods-list-left-item">
                      <p class="item-module-goods-list-br-p2">咨询时间</p>
                      <p class="item-module-goods-list-br-p2">{{item.addTime}}</p>
                    </div>
                  </div>
                  <div class='goods-list-item-info'>
                    <p class="item-module-goods-list-br-p1" style="margin-right: 20px; cursor: pointer" @click.stop="handleSoldIn(item)">咨询记录</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination-bottom">
        <el-pagination @size-change="currentPage" @current-change="currentPage" :current-page.sync="page" :page-size="limit" layout="total, prev, pager, next" :total="total"> </el-pagination>
      </div>
    </div>
    <div v-show="consultationList.length == 0">
      <el-empty :image-size="200"></el-empty>
    </div>
    <el-dialog :visible.sync='showDialog' :show-close='false' width='600px' :close-on-click-modal='false'
               title='咨询记录'>
      <el-form :model='form' style='background: #FFFFFF'>
        <el-row>
          <el-col :span='12'>
            <el-form-item label='规格' style='display: flex'>
              <div slot='label' style='width: 60px'>{{ '规格' }}</div>
              <el-input v-model='form.specName' disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='联系人' style='display: flex'>
              <div slot='label' style='width: 60px'>{{ '联系人' }}</div>
              <el-input v-model='form.intentContactName' disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span='12'>
            <el-form-item label='联系电话' style='display: flex'>
              <div slot='label' style='width: 60px'>{{ '联系电话' }}</div>
              <el-input v-model='form.intentContactPhone' disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='公司名称' style='display: flex'>
              <div slot='label' style='width: 60px'>{{ '公司名称' }}</div>
              <el-input v-model='form.intentCompany' disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item style='display: flex'>
          <div slot='label' style='width: 60px'>{{ '地址' }}</div>
          <div style='display: flex'>
            <el-input :value='form.intentProvName+form.intentCityName+form.intentAreaName' disabled></el-input>
            <el-input v-model='form.intentAddress' style='margin-left: 20px;width: 160px' disabled></el-input>
          </div>

        </el-form-item>
        <el-form-item style='display: flex'>
          <div slot='label' style='width: 60px'>{{ '咨询内容' }}</div>
          <el-input v-model='form.intentContent' style='width: 488px' type='textarea' :rows='2' disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click="showDialog = false" round size='medium'>取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getConsultLeaveMsgList } from '@/api/intentOrder'

export default {
  name: 'ConsultationCom',
  data() {
    return {
      consultationList: [],
      form:{},
      showDialog:false,
      page:1,
      limit:10,
      total:0,
    }
  },
  mounted() {
    this.getLeaveMsgList()
  },
  methods: {
    currentPage(page) {
      this.page = page
      this.getLeaseInfoList()
    },
    getLeaveMsgList() {
      getConsultLeaveMsgList({ page: this.page, limit: this.limit }).then((res) => {
        const { code, msg, data } = res
        if (code === '000000') {
          this.total=res.count
          this.consultationList = data
        } else {
          this.$message({
            message: msg,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    handleSoldIn(info){
      this.showDialog=true
      this.form=info
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.item-module-goods {
  display: flex;
  flex-direction: column;
  justify-content: start;
  .pagination-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}
.item-module-goods ::v-deep .el-divider--horizontal {
  margin: 0px 20px 0px 20px;
  background-color: #eaeaea;
  width: auto;
}
.item-module-goods-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 15px 0px;
  margin: 0px 15px;
  border-bottom-color: #eaeaea;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  .item-module-goods-list-top {
    display: flex;
    flex-direction: row;
    justify-content: start;
    font-size: 14px;
    color: #bbbbbb;
    .item-mess-status-icon {
      margin-left: 8px;
      background: $mainColor;
      padding: 2px 5px;
      font-size: 12px;
      color: white;
      border-radius: 2px;
    }
    .item-module-goods-list-top-text {
      margin-left: 10px;
    }
  }
  .item-module-goods-list-bottom {
    display: flex;
    flex-direction: row;
    justify-content: start;
    .item-module-goods-list-bottom-left {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding-top: 15px;
      .item-module-goods-list-bl-img {
        width: 138px;
        height: 138px;
        background: #eaeaea;
      }
      .item-module-goods-list-bl-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        // justify-content: start;
        .item-module-goods-list-bl-content-p {
          font-size: 14px;
          color: #333333;
          margin-left: 10px;
        }
        .item-module-goods-list-bl-content-p-rent {
          display: flex;
          font-size: 12px;
          color: #bbbbbb;
          margin-left: 10px;
          &:last-child{
            margin-bottom: 0 !important;
          }
        }
        .item-module-goods-list-bl-content-p-rent2 {
          display: flex;
          font-size: 12px;
          color: $mainColor;
          font-weight: bold;
          .text{
            flex-shrink: 0;
            max-width: 160px;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .item-module-goods-list-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      //flex: 1;
      padding-top: 10px;
      .goods-list-item-info{
        flex: 1;
        text-align: center;
        border-right: 1px solid #EAEAEA;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-child{
          flex: 1.1;
        }
        &:last-child{
          border-right: none;
          flex: 0.7;
          flex-direction: row;
          align-items: center;
        }
      }
      .item-module-goods-list-br-p1 {
        font-size: 14px;
        color: $mainColor;
      }
      .item-module-goods-list-br-img2 {
        width: 11px;
        height: 14px;
      }
      .item-module-goods-list-br-p2 {
        font-size: 12px;
        color: #999999;
      }
      .item-module-goods-list-br-left {
        flex: 1;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        .item-module-goods-list-left-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .item-module-goods-list-br-right {
        display: flex;
        flex-direction: row;
        padding-right: 22px;
        flex: 1;
        justify-content: right;
      }
    }
  }
}

// 对话框
.whz-el-dialog {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #333333;
      margin-top: 10px;
    }
  }
  ::v-deep .el-dialog {
    border-radius: 20px;
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 122px;
    height: 38px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }
}
// 对话框
.whz-el-dialog1 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      margin-top: 20px;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #bbbbbb;
      margin-top: 6px;
    }
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 520px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 160px;
    height: 50px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }
}

// 对话框
.whz-el-dialog2 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-info {
      font-size: 14px;
      color: #bbbbbb;
      margin-top: 12px;
    }
    .dialog-service-merchant {
      width: 380px;
      height: 101px;
      background-image: url('@/assets/images/public/icon_service_merchant.png');
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 29px;
      justify-content: space-between;
    }

    .dialog-construction-enterprise {
      width: 380px;
      height: 101px;
      background-image: url('@/assets/images/public/icon_construction_enterprise.png');
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 17px;
      justify-content: space-between;
    }

    .dialog-content-select {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-left: 96px;
    }
    .dialog-content-select-p1 {
      font-size: 20px;
      color: #ffffff;
    }
    .dialog-content-select-p2 {
      margin-top: 5px;
      width: 183px;
      font-size: 12px;
      color: #ffffff;
    }
    .dialog-content-checkbox {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      background-image: url('@/assets/images/public/icon_select_normal_status.png');
      &.active {
        background-image: url('@/assets/images/public/icon_select_status.png');
      }
    }
    .dialog-content-checkbox ::v-deep .el-checkbox__inner {
      width: 24px;
      height: 24px;
    }
    .dialog-content-reason {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 100%;
      margin-top: 20px;
      .dialog-content-reason-item {
        margin-top: 27px;
      }
      .dialog-content-reason-input {
        height: 114px;
        margin-top: 14px;
        ::v-deep .el-textarea__inner {
          font-size: 14px;
          height: 100%;
          padding: 15px;
        }
      }
    }
  }
  ::v-deep .el-input--small .el-input__inner {
    height: 100%;
    text-align: start;
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 460px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 122px;
    height: 38px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  //   ::v-deep .el-dialog__headerbtn {
  //     visibility: hidden;
  //   }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 70px 30px;
  }
  ::v-deep .el-button + .el-button {
    margin-left: 22px;
  }
}
.whz-el-dialog3 {
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-content-title {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    .dialog-content-serviceCharge{
      margin-top: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .serviceCharge{
        font-size: 20px;
        font-weight: bold;
        color: $mainColor;
        line-height: 26px;
        max-height: 80px;
        overflow: auto;
      }
      .tips{
        font-size: 16px;
        color: #BBBBBB;
        line-height: 21px;
      }
      .agreeBtn{
        width: 160px;
        height: 50px;
        line-height: 50px;
        background: $mainColor;
        border-radius: 24px;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        margin: 46px 0 16px;
        cursor: pointer;
      }
      .phone{
        font-size: 14px;
        color: #BBBBBB;
      }
    }
  }
  ::v-deep .el-input--small .el-input__inner {
    height: 100%;
    text-align: start;
  }
  .dialog-content-mini {
    width: 205px;
    height: 205px;
    margin-top: 51px;
  }
  ::v-deep .el-dialog {
    width: 520px;
    height: 378px;
    border-radius: 20px;
    background: linear-gradient(360deg, #ffffff 80%, #f7e6c3 110%);
  }
  ::v-deep .el-button--small {
    border-radius: 34px;
    width: 122px;
    height: 38px;
    font-size: 14px;
  }
  ::v-deep .el-button--default {
    background: #e8e8e8;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: white;
    border-radius: 50%;
    background: #929292;
    padding: 3px;
  }

  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 70px 30px;
  }
  ::v-deep .el-button + .el-button {
    margin-left: 22px;
  }
}
</style>
